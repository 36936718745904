import React, {useEffect , useState} from 'react'
 import { Link } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Loader from "react-loader-spinner";
import parse from 'html-react-parser'

const URL = "https://floridamedicalsurplus.com/admin-panel/";

function Product_Detail({ match }) {

    const [count , setCount] = useState(1);
    const [ProductDetail, setProductDetail] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCartAdded, setIsCartAdded] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [expire , setExpire] = useState('');
    const [condition , setCondition] = useState('');
    const [expirePrice , setExpirePrice] = useState(0);
    const [conditionPrice , setConditionPrice] = useState(0);
    const [price , setPrice] = useState(0);

    useEffect(() => {
        fetch(
            URL+'api/product-detail/'+match.params.param1,
          {
            method: "GET",
            headers: new Headers({
              Accept: "application/json"
            })
          }
        )
          .then(res => res.json())
          .then(response => {
              console.log(response);
            setProductDetail(response.data);
            setPrice(response.data.base_price);
            setIsLoading(false);
            //checkCart(response.data.id);

            fetch(
                URL+'api/check-cart/'+response.data.id,
              {
                method: "GET",
                headers: new Headers({
                  Accept: "application/json"
                })
              }
            )
              .then(res => res.json())
              .then(response => {
                  console.log(response);
                  if(response.count > 0) {
                    setIsCartAdded(true);
                    setCount(response.cartData.qty);
                    setExpirePrice(response.cartData.sell_expire.price);
                    setExpire(response.cartData.sell_expire.id);
                    setConditionPrice(response.cartData.sell_condition.price);
                    setCondition(response.cartData.sell_condition.id);
                  }
              })
              .catch(error => console.log(error));

          })
          .catch(error => console.log(error));
      }, []);

    const checkCart = (id) => {
        
    };

    const decrement = () => {
          if(count > 1) {
            setCount(count - 1);
          }
    };

    const selectExpire = (event) => {
        setExpirePrice(event.target[event.target.selectedIndex].getAttribute('data-price'));
        setExpire(event.target.value);
    }

    const selectCondition = (event) => {
        setConditionPrice(event.target[event.target.selectedIndex].getAttribute('data-price'));
        setCondition(event.target.value);
    }

    const Addtocart = (e) => {
        e.preventDefault();
        if(!isCartAdded) {
            if(expire === '') {
                alert('Please select Device Expiration');
                return;
            }
            if(condition === '') {
                alert('Please select Device Condition');
                return;
            }
            setIsButtonLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ product_id: ProductDetail.id, qty: count,expire : parseInt(expire), condition:parseInt(condition) }),
            };
            fetch(URL+'api/add-to-cart', requestOptions)
                .then(async response => {
                    console.log(response);
                    setIsCartAdded(true);
                    setIsButtonLoading(false);
                })
                .catch(error => console.log(error));
        } else {
            setIsButtonLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ product_id: ProductDetail.id, qty: count,expire : expire, condition:condition }),
            };
            fetch(URL+'api/remove-to-cart', requestOptions)
                .then(async response => {
                    console.log(response);
                    setIsCartAdded(false);
                    setCount(1);
                    setExpirePrice(0);
                    setExpire('');
                    setConditionPrice(0);
                    setCondition('');
                    setIsButtonLoading(false);
                })
                .catch(error => console.log(error));
        }

        

    };

      
    return (
        <div>
            <Header /> 
            <main>
                <div className="breadcrumb-area pt-45 pb-45" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h1>Product Detail</h1>
                                    </div>
                                    <ul className="breadcrumb-menu">
                                        <li><a href="#">Home</a></li>
                                        <li><span>Product Detail</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading  ? <div className="w-100 text-center mt-5"><Loader
                    type="TailSpin"
                    color="#00BFFF"
                    height={100}
                    width={100}
                /></div> : 
                    <div>
                        <section className="shop-banner-area pt-100 pb-70" style={{textAlign:"left"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="product-details-img">
                                            <div className="tab-content" id="myTabContent2">
                                                <div className="tab-pane fade show active" id="home" role="tabpanel">
                                                    <div className="product-large-img">
                                                        <img src={URL +'public/'+ ProductDetail.device_image} alt={ProductDetail.device_name} />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6">
                                        <div className="product-details-wrapper mb-30">
                                            <div className="product-text">
                                                <h5>{ProductDetail.device_brand.brand_name}</h5>
                                                <h2>{ProductDetail.device_name}</h2>
                                                <span>${parseInt(price) + parseInt(expirePrice) + parseInt(conditionPrice)}</span>
                                            </div>
                                            <div className="product-variant">
                                                <div className="product-desc">
                                                    <p>{ProductDetail.short_description}</p>
                                                </div>
                                                {ProductDetail.expire ? 
                                                <div className="product-desc">
                                                    <div className="mb-3">
                                                        <label >Expiration:</label>
                                                        <select className="form-select form-control" id="expire" value={expire} onChange={(e) => selectExpire(e)}>
                                                            <option data-price='0'>Select Device Expiration</option>
                                                            {
                                                                ProductDetail.expire.map(exp =>
                                                                <option value={exp.id} data-price={exp.price}>{exp.name}</option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                </div> : '' }
                                                {ProductDetail.condition ? 
                                                <div className="product-desc">
                                                    <div className="mb-3">
                                                        <label >Condition:</label>
                                                        <select className="form-select form-control" id="condition" value={condition} onChange={(e) => selectCondition(e)}>
                                                            <option data-price='0'>Select Device Condition</option>
                                                            {
                                                                ProductDetail.condition.map(cond =>
                                                                <option value={cond.id} data-price={cond.price}>{cond.name}</option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                </div> :''}
                                                <div className="product-action-details ">
                                                    <div className="product-details-action">
                                                        <form action="#">
                                                            <div className="plus-minus">
                                                                <div className="cart-plus-minus">
                                                                    <input type="text" value={count} />
                                                                    <div className="dec qtybutton" onClick={() => decrement()}>-</div>
                                                                    <div className="inc qtybutton" onClick={() => setCount(count + 1)}>+</div>
                                                                </div>
                                                            </div>
                                                            <div className="details-cart-btn">
                                                            {isButtonLoading  ? <div className="w-100 text-center"><Loader
                                                                    type="TailSpin"
                                                                    color="#00BFFF"
                                                                    height={40}
                                                                    width={200}
                                                                /></div> : 
                                                                (!isCartAdded)  ?
                                                                (<button className="c-btn red-btn" onClick={(e) => Addtocart(e)} >
                                                                 add to cart
                                                                <i className="far fa-plus"></i></button>)
                                                                    : (<>
                                                                        <Link to="/cart" className="c-btn theme-btn"> Checkout Now</Link>
                                                                        <Link to="/shop" className="c-btn theme-btn"> Add More Items</Link>
                                                                    </> )
                                                                 
                                                            }
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="product-desc-area pb-60">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 mb-30">
                                        <div className="bakix-details-tab">
                                            <ul className="nav text-center pb-30 mb-50" id="myTab" role="tablist">
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="desc-tab" data-toggle="tab" href="#id-desc" role="tab"
                                                        aria-controls="home" aria-selected="true">Description </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="id-add-in" data-toggle="tab" href="#id-add" role="tab"
                                                        aria-controls="profile" aria-selected="false">Extra Information</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade show active" id="id-desc" role="tabpanel" aria-labelledby="desc-tab">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="event-text">
                                                            {parse(ProductDetail.description)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="id-add" role="tabpanel" aria-labelledby="id-add-in">
                                                <div className="additional-info">
                                                    <div className="table-responsive">
                                                        {parse(ProductDetail.extra_description)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    }
            </main>
            
            <Footer />
        </div>
    )
}

export default Product_Detail
