import React, {useEffect , useState} from 'react'
import {BrowserRouter as Router, Link, useHistory} from "react-router-dom";

const URL = "https://floridamedicalsurplus.com/admin-panel/";

function Header() {
    const [count , setCount] = useState(0);
    const login = localStorage.getItem("isLoggedIn"); 
    let setting = JSON.parse(localStorage.getItem('setting'));
    console.log('setting',setting);
    function onLogoutHandler(e)  {
        e.preventDefault();
        localStorage.clear();
        window.location.reload(); 
    };

    useEffect(() => {
        fetch(
            URL+'api/cart',
          {
            method: "GET",
            headers: new Headers({
              Accept: "application/json"
            })
          }
        )
          .then(res => res.json())
          .then(response => {
              console.log(response);
              setCount(response.count)
          })
          .catch(error => console.log(error));
      }, [count]);

    return (
        <header >
            <div className="header-top-area pl-165 pr-165" style={{background: "#D90D32",Color:"white"}}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9 col-lg-6 col-md-6">
                            <div className="header-top-wrapper">
                                <div className="header-top-info d-none d-xl-block f-left">
                                    <span><i className="fas fa-money-bill-alt"></i> Get Up to $20 in Bonuses on your First Order!</span>
                                </div>
                                <div className="header-link f-left">
                                    <span><a href="#"><i className="far fa-phone"></i> {setting.phone}</a></span>
                                    <span>
                                        <a href="mailto:support@floridamedicalsurplus.com"><i className="far fa-envelope"></i> {setting.email}</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6">
                            <div className="header-top-right text-md-right">
                               <div className="shop-menu">
                                   <ul>
                                       {login ? 
                                        <li>
                                            <Link to="/dashboard"><i className="fal fa-user-circle"></i> Dashboard</Link>  
                                            <Link to="#" onClick={onLogoutHandler} className="ml-3"><i className="fal fa-user-circle"></i> Logout</Link>   
                                        </li>
                                        
                                       :
                                       <li>
                                            <Link to="/login"><i className="fal fa-user-circle"></i> Login</Link>   
                                        </li> }
                                       <li>
                                        <Link to="/cart"><i className="far fa-cart-plus"></i> Cart</Link></li>
                                   </ul>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="sticky-header" className="main-menu-area menu-01 pl-165 pr-165">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-3">
                            <div className="logo">
                                <Link to="/">
                                    <img src={URL +'public/'+setting.site_logo} alt="" />
                                    {/* <h2>LOGO</h2> */}
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-1 col-lg-1">
                        </div>
                        <div className="col-xl-8 col-lg-8 d-none d-lg-block">
                            <div className="header-right f-right">
                                <div className="header-search f-right d-none d-xl-block">
                                    {/* <form className="header-search-form">
                                        <input placeholder="Search" type="text" />
                                        <button type="submit"><i className="far fa-search"></i></button>
                                    </form> */}
                                </div>
                            </div>
                            <div className="main-menu">
                                <nav id="mobile-menu">
                                    <ul>
                                        <li className="active">
                                            <Link to="/">Home </Link>
                                        </li>
                                        <li>
                                            <Link to="/shop">Sell Now </Link>
                                        </li>
                                        <li>
                                            <Link to="/terms">Terms </Link>
                                        </li>
                                        {/* <li><Link to="/privacy-policy">Privacy Policy </Link></li> */}
                                        {/* <li><a href="#">Reviews </a></li> */}
                                        <li className="d-none">
                                            <Link to="/blog">Blog </Link>
                                        </li>
                                        <li>
                                            <Link to="/faqs">FAQ's </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">Contact Us </Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mobile-menu"></div>
                        </div>
                    </div>
                </div>
            </div>
            <Link  to="/cart" style={{position: 'fixed', zIndex:2147483647, marginRight:'25px', background: '#662D91' ,height: '50px', width: '50px', right: '100px', bottom: '77px', textAlign: 'center', borderRadius: '50%', fontSize: '20px', lineHeight: '50px'}}>
                <span className='text-white' style={{ position: 'absolute'}}> 
                    <i className="far fa-shopping-basket"></i>
                </span> 
                <span class="badge badge-light" style={{position:'relative',top: "-55%",border: '1px solid black',borderRadius: '50%'}}>{count}</span>
            </Link>
           
        </header>

       
    )
}

export default Header
