import React, {useEffect , useState} from 'react';
import Home from './front/Home';
import Shop from './front/Shop';
import Product from './front/Product';
import Product_Detail from './front/Product_Detail';
import Terms from './front/Terms';
import Privacy from './front/Privacy';
import Contact from './front/Contact';
import Blog from './front/Blog';
import Blog_Detail  from './front/Blog_Detail';
import Login from './front/Login';
import Register from './front/Register';
import Cart from './front/Cart';
import Dashboard from './front/Dashboard';
import Checkout from './front/Checkout';
import Success from './front/Success';
import Faq from './front/Faq';
import { BrowserRouter as Router , Route} from 'react-router-dom';
import Loader from "react-loader-spinner";
import Damage from './front/Demage';

const URL = "https://floridamedicalsurplus.com/admin-panel/";

function App() {

  const [isLoading, setIsLoading] = useState(true);
  const [SettingInfo, setSettingInfo] = useState();

  useEffect(() => {
    fetch(
        URL+'api/setting',
      {
        method: "GET",
        headers: new Headers({
          Accept: "application/json"
        })
      }
    )
      .then(res => res.json())
      .then(response => {
          console.log(response);
          setSettingInfo(response.data);
          localStorage.setItem('setting',JSON.stringify(response.data));
          setIsLoading(false);
      })
      .catch(error => console.log(error));
  }, []);

  return (
      
      <div className="App">
          {isLoading ? <div className="w-100 text-center mt-75"><Loader
                      type="TailSpin"
                      color="#00BFFF"
                      height={100}
                      width={100}
                  /></div> :
          <Router>
            <Route exact path="/" component={Home} />
            <Route exact path="/shop" component={Shop} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/products/:param1" component={Product} />
            <Route exact path="/product-detail/:param1" component={Product_Detail} />
            <Route exact path="/cart" component={Cart} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog-detail/:param1" component={Blog_Detail} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/success/:id" component={Success} />
            <Route exact path="/faqs" component={Faq} />
            <Route exact path="/damage" component={Damage} />
          </Router>
        }
      </div>
    
  );
}

export default App;
