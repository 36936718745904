import React from 'react';
import Header from './layout/Header';
import Footer from './layout/Footer';

function Blog_Detail() {
    return (
        <div>
            <Header /> 
            <main>
                <div className="breadcrumb-area pt-45 pb-45" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h2>Blog Detail</h2>
                                    </div>
                                    <ul className="breadcrumb-menu">
                                        <li><a href="#">Home</a></li>
                                        <li><span>Blog Detail</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blog-area pt-105 pb-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 mb-30 widget ">
                                <div className="blog-details blog-standard">
                                    <div className="blog-wrapper">
                                        <div className="blog-img pos-rel">
                                            <img src="/front/assets/img/blog/b-01.jpg" className="img-fluid" alt="" style={{width:"100%"}}/>
                                        </div>
                                        <div className="blog-text">
                                            <div className="blog-meta">
                                                <span><i className="far fa-calendar-alt"></i>25 Aug 2020</span>
                                            </div>
                                            <h4>Monthly Web Development To Update React Hooks Cons Wether Says</h4>
                                        </div>
                                        <div className="post-text  mb-20">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                                incididunt ut labore et dolore magna
                                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat.
                                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                                                nulla pariatur. Excepteur sint
                                                occaecat cupidatat non proident, sunt in culpa qui officia deserunt.</p>

                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                                incididunt ut labore et dolore magna
                                                aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequat.
                                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                                                fugiat nulla pariatur. Excepteur
                                                sint
                                                occaecat cupidatat non proident, sunt in culpa qui officia.</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Blog_Detail
