import React, {useEffect , useState} from 'react'
import { Link , useHistory  } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Loader from "react-loader-spinner";
import axios from "axios";

const URL = "https://floridamedicalsurplus.com/admin-panel/";
const token = localStorage.getItem("token");


function Checkout() {

    const [ProductList, setProductList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState('');
    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState('');
    const [state, setState] = useState('');
    const [stateError, setStateError] = useState('');
    const [country, setCountry] = useState('');
    const [countryError, setCountryError] = useState('');
    const [pin, setPin] = useState('');
    const [pinError, setPinError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [noteInfo, setNoteInfo] = useState('');
    const [terms, setTerms] = useState(false);
    const [termsError, setTermsError] = useState('');
    const [shipping, setShipping] = useState('print');
    const [payment, setPayment] = useState('Check');
    const [paymentPlaceholder, setPaymentPlaceholder] = useState('Enter First & Last Name');
    const [paymentInfo, setPaymentInfo] = useState('');
    const [paymentInfoError, setPaymentInfoError] = useState('');
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const history = useHistory();
    var totalPrice = 0;
    var totalitem = 0;
    var bonus = 0;
    useEffect(() => {
        fetch(
            URL+'api/cart',
          {
            method: "GET",
            headers: new Headers({
              Accept: "application/json"
            })
          }
        )
          .then(res => res.json())
          .then(response => {
              console.log(response);
              setProductList(response.data);
            setIsLoading(false);
          })
          .catch(error => console.log(error));

          fetch(
            URL+'api/get_user/'+localStorage.getItem("user_id"),
          {
            method: "GET",
            headers: new Headers({
              Accept: "application/json"
            })
          }
        )
          .then(res => res.json())
          .then(response => {
              console.log(response.user.email);
              setEmail(response.user.email);
            
          })
          .catch(error => console.log(error));

      }, []);
    
    function onSubmitHandler(e){
        e.preventDefault();
        console.log(terms);
        if(terms == false) {
            setTermsError('Please Agree terms & conditions!');
            return false;
        }
        setIsLoadingButton(true);
        const signupData = { 
            first_name:firstName, 
            last_name:lastName, 
            company_name:companyName, 
            user_id:localStorage.getItem("user_id"), 
            address:address, 
            city:city, 
            state:state, 
            country:country, 
            zip_code:pin, 
            email:email, 
            phone:phone, 
            order_note:noteInfo, 
            shipping:shipping, 
            payment:payment, 
            payment_info:paymentInfo, 
        }
        axios
            .post(URL+'api/make-order', signupData)
            .then((response) => {
            
                console.log(response.data);
                if(response.data.error) {
                    if(response.data.error.first_name) {
                        response.data.error.first_name.map((ERROR, index) => (
                            setFirstNameError(ERROR)
                        ))
                    }
                    if(response.data.error.last_name) {
                        response.data.error.last_name.map((ERROR, index) => (
                            setLastNameError(ERROR)
                        ))
                    }
                    if(response.data.error.email) {
                        response.data.error.email.map((ERROR, index) => (
                            setEmailError(ERROR)
                        ))
                    }
                    if(response.data.error.phone) {
                        response.data.error.phone.map((ERROR, index) => (
                            setPhoneError(ERROR)
                        ))
                    }
                    if(response.data.error.address) {
                        response.data.error.address.map((ERROR, index) => (
                            setAddressError(ERROR)
                        ))
                    }
                    if(response.data.error.city) {
                        response.data.error.city.map((ERROR, index) => (
                            setCityError(ERROR)
                        ))
                    }
                    if(response.data.error.state) {
                        response.data.error.state.map((ERROR, index) => (
                            setStateError(ERROR)
                        ))
                    }
                    if(response.data.error.country) {
                        response.data.error.country.map((ERROR, index) => (
                            setCountryError(ERROR)
                        ))
                    }
                    if(response.data.error.zip_code) {
                        response.data.error.zip_code.map((ERROR, index) => (
                            setPinError(ERROR)
                        ))
                    }
                    if(response.data.error.payment_info) {
                        response.data.error.payment_info.map((ERROR, index) => (
                            setPaymentInfoError(ERROR)
                        ))
                    }
                    
                }

                if(response.data.success) {
                    alert('Order Created Successfully. We will contact you shortly!');
                    history.push("/success/"+response.data.data.id);
                }
                    
                setIsLoadingButton(false);
                

                if (!response.status) {
                    console.log(response.data.message)
                    setTimeout(() => {
                        console.log("error")
                    }, 2000);
                }
            });
    };

    function onRadioChange(e){
        e.preventDefault();
        setPayment(e.target.value);
    };
    return (
        <div>
            <Header /> 
            <main>
            <div className="breadcrumb-area pt-45 pb-45" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h1>Checkout</h1>
                                    </div>
                                    <ul className="breadcrumb-menu">
                                        <li><a href="#">Home</a></li>
                                        <li><span>Checkout</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="checkout-area pb-70 mt-75">
                    <div className="container">
                        <form action="#">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="checkbox-form">
                                        <h2>Billing Details</h2>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="checkout-form-list">
                                                    <label>First Name <span className="required">*</span></label>
                                                    <input type="text" placeholder="First Name" value={firstName}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setFirstName(e.target.value);
                                                        setFirstNameError('');
                                                    } }
                                                    />
                                                    <div className="product-text mt-0 mb-3">
                                                        {firstNameError ? <span><span className="error">{firstNameError}</span><br/></span> :''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="checkout-form-list">
                                                    <label>Last Name <span className="required">*</span></label>
                                                    <input type="text" placeholder="Last Name" 
                                                    value={lastName} 
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setLastName(e.target.value);
                                                        setLastNameError('');
                                                    } }/>
                                                    <div className="product-text mt-0 mb-3">
                                                        {lastNameError ? <span><span className="error">{lastNameError}</span><br/></span> :''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="checkout-form-list">
                                                    <label>Company Name (Optional)</label>
                                                    <input type="text" placeholder="Company Name" 
                                                    value={companyName} 
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setCompanyName(e.target.value);
                                                    } }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="checkout-form-list">
                                                    <label>Address <span className="required">*</span></label>
                                                    <input type="text" placeholder="Street address" 
                                                    value={address} 
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setAddress(e.target.value);
                                                        setAddressError('');
                                                    } }/>
                                                    <div className="product-text mt-0 mb-3">
                                                        {addressError ? <span><span className="error">{addressError}</span><br/></span> :''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="checkout-form-list">
                                                    <label>Town / City <span className="required">*</span></label>
                                                    <input type="text" placeholder="Town / City" 
                                                    value={city} 
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setCity(e.target.value);
                                                        setCityError('');
                                                    } }/>
                                                    <div className="product-text mt-0 mb-3">
                                                        {cityError ? <span><span className="error">{cityError}</span><br/></span> :''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="checkout-form-list">
                                                    <label>State <span className="required">*</span></label>
                                                    <input type="text" placeholder="State" 
                                                    value={state} 
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setState(e.target.value);
                                                        setStateError('');
                                                    } }
                                                    />
                                                    <div className="product-text mt-0 mb-3">
                                                        {stateError ? <span><span className="error">{stateError}</span><br/></span> :''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="checkout-form-list">
                                                    <label>Country <span className="required">*</span></label>
                                                    <input type="text" placeholder="Country" 
                                                    value={country} 
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setCountry(e.target.value);
                                                        setCountryError('');
                                                    } }/>
                                                    <div className="product-text mt-0 mb-3">
                                                        {countryError ? <span><span className="error">{countryError}</span><br/></span> :''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="checkout-form-list">
                                                    <label>Postcode / Zip <span className="required">*</span></label>
                                                    <input type="text" placeholder="Postcode / Zip" 
                                                    value={pin} 
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setPin(e.target.value);
                                                        setPinError('');
                                                    } }/>
                                                    <div className="product-text mt-0 mb-3">
                                                        {pinError ? <span><span className="error">{pinError}</span><br/></span> :''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="checkout-form-list">
                                                    <label>Email Address <span className="required">*</span></label>
                                                    <input type="email" placeholder="Email Address" 
                                                    value={email} 
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setEmail(e.target.value);
                                                        setEmailError('');
                                                    } }
                                                    />
                                                    <div className="product-text mt-0 mb-3">
                                                        {emailError ? <span><span className="error">{emailError}</span><br/></span> :''}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="checkout-form-list">
                                                    <label>Phone <span className="required">*</span></label>
                                                    <input type="text" placeholder="Phone" 
                                                    value={phone} 
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setPhone(e.target.value);
                                                        setPhoneError('');
                                                    } }
                                                    />
                                                    <div className="product-text mt-0 mb-3">
                                                        {phoneError ? <span><span className="error">{phoneError}</span><br/></span> :''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="different-address">
                                            <div className="order-notes">
                                                <div className="checkout-form-list">
                                                    <label>Order Notes</label>
                                                    <textarea id="checkout-mess" cols="30" rows="20" placeholder="Notes about your order"
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setNoteInfo(e.target.value);
                                                    } } value={noteInfo} ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="your-order mb-30 ">
                                        <div className="payment-method mt-0">
                                            <h3>Shipping Method</h3>
                                            <div className="accordion">
                                                <div className="card d-none">
                                                    <div className="card-header" id="headingOne">
                                                        <div className="form-check">
                                                            <label className="form-check-label" for="print" onClick={() => {
                                                                    setShipping('print');
                                                                } }>
                                                            <input className="form-check-input" type="radio" name="shipping_type" value="print" id="print" checked={shipping === 'print'} />
                                                                Print a Shipping Label
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="card">
                                                    <div className="card-header mb-0" id="headingThree">
                                                        <div className="form-check">
                                                            <label className="form-check-label" for="ask" onClick={() => {
                                                                setShipping('ask');
                                                            } }>
                                                            <input className="form-check-input" type="radio" name="shipping_type" value="ask" id="ask" checked={shipping === 'ask'}/>
                                                            Request a prepaid shipping label emailed to me.
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card">
                                                    <div className="card-header" id="headingTwo">
                                                        <div className="form-check">
                                                            <label className="form-check-label" forHtml="own" onClick={() => {
                                                                setShipping('own');
                                                            } }>
                                                            <input className="form-check-input" type="radio" name="shipping_type" value="own" id="own" checked={shipping === 'own'}/>
                                                            {/* Request that a label and order forms be sent to me via the postal service */}
                                                            Request A Mailing Kit And Order Forms Be Sent To Me Via Mail
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion mt-5" id="accordionExample">
                                                <h3>Payment Method</h3>
                                                <div className="card">
                                                    <div className="card-header" >
                                                        <div className="form-check form-check-inline">
                                                            <label className="form-check-label" forHtml="inlineRadio1" onClick={() => {
                                                                setPayment("Check");
                                                                setPaymentPlaceholder('Enter First & Last Name');
                                                            }}>
                                                            <input className="form-check-input" type="radio" name="payment" id="inlineRadio1" value="Check" checked={payment === "Check"}/>
                                                            Check</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                        <label className="form-check-label" forHtml="inlineRadio2" onClick={() => {
                                                                setPayment("Cash App");
                                                                setPaymentPlaceholder('Enter $Cash Tag,Phone Number or Email');
                                                            }}>
                                                            <input className="form-check-input" type="radio" name="payment" id="inlineRadio2" value="Cash App"  checked={payment === "Cash App"} />
                                                            Cash APP</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                        <label className="form-check-label" forHtml="inlineRadio3" onClick={() => {
                                                                setPayment("Zelle");
                                                                setPaymentPlaceholder('Enter Phone Number or Email');
                                                            }}>
                                                            <input className="form-check-input" type="radio" name="payment" id="inlineRadio3" value="Zelle" checked={payment === "Zelle"}  />
                                                            Zelle</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                        <label className="form-check-label" forHtml="inlineRadio4" onClick={() => {
                                                                setPayment("PayPal");
                                                                setPaymentPlaceholder('Enter PayPal Email');
                                                            }}>
                                                            <input className="form-check-input" type="radio" name="payment" id="inlineRadio4" value="PayPal" checked={payment === "PayPal"}/>
                                                            PayPal</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                        <label className="form-check-label" forHtml="inlineRadio5" onClick={() => {
                                                                setPayment("Venmo");
                                                                setPaymentPlaceholder('Enter @username, Phone or Email');
                                                            }}>
                                                            <input className="form-check-input" type="radio" name="payment" id="inlineRadio5" value="Venmo" checked={payment === "Venmo"}/>
                                                            Venmo</label>
                                                        </div>
                                                        
                                                    </div>

                                                    <div id="collapseOne"  >
                                                        <div className="card-body">
                                                            <div className="checkout-form-list">
                                                                <label>
                                                                    {payment}
                                                                    {/* {payment == 'Check'? 'Check' : ''}
                                                                    {payment == 'Cash App'? 'Cash App' : ''}
                                                                    {payment == 'Check'? 'Check' : ''}
                                                                    {payment == 'Check'? 'Check' : ''}
                                                                    {payment == 'Check'? 'Check' : ''}
                                                                    {payment == 'Check'? 'Check' : ''} */}
                                                                    
                                                                    <span className="required">*</span>
                                                                </label>
                                                                <input type="text" placeholder={paymentPlaceholder} name="payment_info" onChange={(e) => {
                                                                e.preventDefault();
                                                                setPaymentInfo(e.target.value);
                                                                setPaymentInfoError('');
                                                            } }/>
                                                            <div className="product-text mt-0 mb-3">
                                                                {paymentInfoError ? <span><span className="error">{paymentInfoError}</span><br/></span> :''}
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <h3 className='mt-5'>Your order</h3>
                                            <div className="your-order-table table-responsive">
                                                {isLoading  ? <div className="w-100 text-center mt-5"><Loader
                                                        type="TailSpin"
                                                        color="#00BFFF"
                                                        height={100}
                                                        width={100}
                                                    /></div> : 
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th className="product-name">Product</th>
                                                            <th className="product-total">What you'll get</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {ProductList.map((list, index) => (
                                                            <tr className="cart_item">
                                                                <td className="product-name">
                                                                {list.sell_device.device_name} <strong className="product-quantity"> × {list.qty}</strong>
                                                                </td>
                                                                
                                                                <td className="product-total">
                                                                    <span className="amount">${(parseInt(list.sell_device.base_price) + parseInt(list.sell_condition.price) + parseInt(list.sell_expire.price)) * list.qty} </span>
                                                                    <span className="d-none">{totalitem += (parseInt(list.qty))} {totalPrice += (parseInt(list.sell_device.base_price) + parseInt(list.sell_condition.price) + parseInt(list.sell_expire.price)) * list.qty }</span>
                                                                </td>
                                                            </tr> 
                                                        ))}
                                                        {(totalitem > 4) ? 
                                                         <tr className="cart_item">
                                                            <th>Bonus</th>
                                                            <td><strong><span className="amount">
                                                            {(totalitem > 4 && totalitem < 10) 
                                                                ? <>
                                                                    <div className='d-none'>{bonus = 10}</div>
                                                                    $10.00
                                                                </> : 
                                                                <>{(totalitem > 9 && totalitem < 15) 
                                                                    ? <>
                                                                    <div className='d-none'>{bonus = 15}</div>
                                                                    $15.00
                                                                </> : <>
                                                                <div className='d-none'>{bonus = 20}</div>
                                                                    $20.00
                                                                </>}</>
                                                            }
                                                                </span></strong>
                                                            </td>
                                                        </tr> : ''}
                                                        <tr className="cart_item">
                                                            <th>What you'll get</th>
                                                            <td><strong><span className="amount">${totalPrice + bonus}</span></strong>
                                                            </td>
                                                        </tr>
                                                        <tr className="cart_item">
                                                            <th>Shipping</th>
                                                            <td><strong><span className="amount">Free Shipping</span></strong>
                                                            </td>
                                                        </tr>
                                                        <tr className="cart_item">
                                                            <th>Tax</th>
                                                            <td><strong><span className="amount">$0.00</span></strong>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr className="order-total">
                                                            <th>Total</th>
                                                            <td><strong><span className="amount">${totalPrice + bonus}</span></strong>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                                }
                                            </div>
                                            
                                            <p>Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our <Link to="/privacy-policy">Privacy Policy </Link></p>

                                            <input type="checkbox"name="terms" onChange={(e) => {
                                                e.preventDefault();
                                                setTerms(true);
                                                setTermsError('');
                                            } }/>
                                           
                                            &nbsp; I HAVE READ AND AGREE TO THE WEBSITE <Link to="/terms" className='text-danger'>TERMS AND CONDITIONS </Link>
                                            <div className="product-text mt-0 mb-3">
                                                {termsError ? <span><span className="error">{termsError}</span><br/></span> :''}
                                            </div>

                                            <div className="order-button-payment mt-20">
                                            {isLoadingButton  ? <div className="w-100 text-center"><Loader
                                                type="TailSpin"
                                                color="#00BFFF"
                                                height={50}
                                                width={100}
                                            /></div> :
                                                <button type="submit" className="c-btn theme-btn" onClick={onSubmitHandler}>Place order</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default Checkout
