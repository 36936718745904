import React from 'react';
import {BrowserRouter as Router, Link} from "react-router-dom";

const URL = "https://floridamedicalsurplus.com/admin-panel/";

function Footer() {
    
    let setting = JSON.parse(localStorage.getItem('setting'));

    return (
        <footer className='bg-dark'>
            <div className="footer-area pt-80 pb-45">
              <div className="container">
                  <div className="row">
                        
                        {/* <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="footer-wrapper mb-30">
                                <h3 className="footer-title text-white">About Company</h3>
                                <div className="footer-icon">
                                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                    <a href="#"><i className="fab fa-google-plus-g"></i></a>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="footer-wrapper mb-30">
                                <h3 className="footer-title text-white">Information</h3>
                                <div className="footer-link">
                                    <ul>
                                        <li><a href="/contact">Contact Us</a></li>
                                        <li><a href="#">Reviews</a></li>
                                        <li><a href="/terms">Terms & Conditions</a></li>
                                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="footer-wrapper mb-30">
                                <h3 className="footer-title text-white">My Account</h3>
                                <div className="footer-link">
                                    <ul>
                                        <li><a href="/login">Sign In</a></li>
                                        <li><a href="/register">Sign Up</a></li>
                                        <li><a href="/damage">Damage Guidelines</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6">
                            <div className="footer-wrapper mb-30">
                                <h3 className="footer-title text-white">Contact Info</h3>
                                <div className="footer-link">
                                    <ul className="contact-address-link">
                                        {/* <li>
                                            <div className="contact-address-text">
                                                <p className="mb-0"><b>Address :</b> {setting.address}</p>
                                            </div>
                                        </li> */}
                                        <li>
                                            <div className="contact-address-text">
                                                <p className="mb-0"><b>Phone Number :</b> {setting.phone}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-address-text">
                                                <p className="mb-0"><b>Email:</b> {setting.email}</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="contact-address-text">
                                                <p className="mb-0"><b>Business Hours:</b> {setting.working_hour}</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6">
                            <div className="footer-wrapper mb-30">
                                <h3 className="footer-title text-white">Follow Us</h3>
                                {/* <div className="footer-text">
                                    <p>
                                    {setting.about_company} </p>
                                </div> */}
                                <div className="footer-icon">
                                    {/* <Link to={setting.facebook_page_link} target="_blank"><i className="fab fa-facebook-f"></i></Link> */}
                                    <a href={setting.facebook_page_link} target="_blank"><i className="fab fa-facebook-f"></i></a>
                                    {/* <Link to={setting.github_page_link} target="_blank"><i className="fab fa-github"></i></Link> */}
                                    {/* <Link to={setting.google_page_link} target="_blank"><i className="fab fa-google-plus-g"></i></Link>
                                    <Link to={setting.instagram_page_link} target="_blank"><i className="fab fa-instagram"></i></Link> */}
                                    <a href={setting.google_page_link} target="_blank"><i className="fab fa-google-plus-g"></i></a>
                                    <a href={setting.instagram_page_link} target="_blank"><i className="fab fa-instagram"></i></a>
                                    {/* <Link to={setting.linkdin_page_link} target="_blank"><i className="fab fa-linkedin"></i></Link> */}
                                    {/* <Link to={setting.skype_page_link} target="_blank"><i className="fab fa-skype"></i></Link> */}
                                </div>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
            <div className="footer-bottom-area mr-70 ml-70 pt-25 pb-25">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="copyright">
                                <p>Copyright <i className="far fa-copyright"></i> 2022 <a href="#">Florida Medical Surplus</a>. All Rights Reserved</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <div className="footer-bottom-link f-right">
                                <ul>
                                    <li><Link to="/">Home </Link></li>
                                    <li><Link to="/terms">Terms </Link></li>
                                    <li><Link to="/shop">Sell Now </Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy </Link></li>
                                    <li><Link to="/contact">Contact Us </Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </footer>
    )
}

export default Footer
