import React, {useEffect , useState} from 'react'
import { Link } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Loader from "react-loader-spinner";
import axios from "axios";

const URL = "https://floridamedicalsurplus.com/admin-panel/";

function Register() {
    const [name, setName] = useState('');
    const [userError, setuserError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    function handleChange(e)  {
        e.preventDefault();
        setName(e.target.value);
        setuserError('');
    };
    function handleEmail(e)  {
        e.preventDefault();
        setEmail(e.target.value);
        setEmailError('');
    };
    function handlePassword(e)  {
        e.preventDefault();
        setPassword(e.target.value);
        setPasswordError('');
    };
    function handleConfirmPassword(e)  {
        e.preventDefault();
        setConfirmPassword(e.target.value);
    };

    function onSubmitHandler(e){
        e.preventDefault();
        if(password != confirmPassword) {
            alert('Confirm password is not matched!');
            return false;
        }
        setIsLoading(true);
        const signupData = { name:name, email:email,password:password }
        axios
            .post(URL+'api/register', signupData)
            .then((response) => {
                
                console.log(response.data);
                if(response.data.error) {
                    if(response.data.error.name) {
                        response.data.error.name.map((ERROR, index) => (
                            setuserError(ERROR)
                        ))
                    }
                    if(response.data.error.email) {
                        response.data.error.email.map((ERROR, index) => (
                            setEmailError(ERROR)
                        ))
                    }
                    if(response.data.error.password) {
                        response.data.error.password.map((ERROR, index) => (
                            setPasswordError(ERROR)
                        ))
                    }
                    alert('Please FIx Following Errors!')
                }

                if(response.data.success) {
                    alert(response.data.message);
                    setName('');
                    setEmail('');
                    setPassword('');
                    setConfirmPassword('');
                }
                    
                setIsLoading(false);
                

                if (!response.status) {
                    console.log(response.data.message)
                    setTimeout(() => {
                        console.log("error")
                    }, 2000);
                }
            });

    };
    return (
        <div>
            <Header /> 
            <main>
                <div className="breadcrumb-area pt-45 pb-45" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h1>Register</h1>
                                    </div>
                                    <ul className="breadcrumb-menu">
                                        <li><a href="#">Home</a></li>
                                        <li><span>Register</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="login-area pt-100 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="basic-login">
                                    <h2 className="text-center mb-60">Register From Here</h2>
                                    <div className="product-text">
                                        {userError ? <span><span className="error">{userError}</span><br/></span> :''}
                                        {emailError ? <span><span className="error">{emailError}</span><br/></span>: ''}
                                        {passwordError ? <span><span className="error">{passwordError}</span><br/></span> :''}
                                    </div>

                                    <form action="#">
                                        <label for="name">Name <span>**</span></label>
                                        <input id="name" type="text" placeholder="Enter Name..." value={name} onChange={handleChange} />
                                        <label for="email">Email Address <span>**</span></label>
                                        <input id="email" type="email" placeholder="Enter Email address..." value={email} onChange={handleEmail} />
                                        <label for="pass">Password <span>**</span></label>
                                        <input id="pass" type="password" placeholder="Enter password..." value={password} onChange={handlePassword} />
                                        <label for="Cpass">Confirm password <span>**</span></label>
                                        <input id="Cpass" type="password" placeholder="Enter confirm password..." value={confirmPassword} onChange={handleConfirmPassword} />
                                        {isLoading  ? <div className="w-100 text-center"><Loader
                                            type="TailSpin"
                                            color="#00BFFF"
                                            height={50}
                                            width={100}
                                        /></div> :
                                            <button className="c-btn theme-btn-2 w-100"  onClick={onSubmitHandler}>Register Now</button>
                                        }
                                        <div className="or-divide"><span>or</span></div>
                                        {/* <button className="c-btn theme-btn w-100">Register Now</button> */}
                                        <Link to="/login"  className="c-btn theme-btn w-100">Login</Link>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
             </main>
            <Footer />
        </div>
    )
}

export default Register
