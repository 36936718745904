import React , { useState, useEffect } from 'react';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { Link , useHistory  } from 'react-router-dom';
import Loader from "react-loader-spinner";

const URL = "https://floridamedicalsurplus.com/admin-panel/";

function Dashboard() {
    const history = useHistory();
    const login = localStorage.getItem("isLoggedIn");

    if(!login) {
        history.push("/");
    }

    const [commitHistory, setCommitHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch(
            URL+'api/user_order/'+localStorage.getItem("user_id"),
          {
            method: "GET",
            headers: new Headers({
              Accept: "application/json"
            })
          }
        )
          .then(res => res.json())
          .then(response => {
              console.log(response);
            setCommitHistory(response.data);
            setIsLoading(false);
          })
          .catch(error => console.log(error));
      }, []);

    return (
        <div>
            <Header /> 
            <main>
                <div className="breadcrumb-area pt-25 pb-25" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h1>Dashboard</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="cart-area pt-100 pb-100">
                    <div className="container">
                        <div className="row">
                        {isLoading  ? <div className="w-100 text-center mt-5 col-12"><Loader
                            type="TailSpin"
                            color="#00BFFF"
                            height={100}
                            width={100}
                        /></div> :
                            <div className="col-12">
                                <form action="#">
                                    <div className="table-content table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="product-thumbnail">Name</th>
                                                    <th className="cart-product-name">Address</th>
                                                    <th className="product-price">No Of Devices</th>
                                                    <th className="product-quantity">Total Cost</th>
                                                    <th className="product-quantity">Order Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {commitHistory.map((list, index) => (
                                                <tr>
                                                    <td className="product-thumbnail">{list.first_name+' '+list.last_name}</td>
                                                    <td className="product-name">{list.address+', '+list.city+', '+list.state+', '+list.country+', '+list.zip_code}</td>
                                                    <td className="product-quantity">
                                                    <span className="amount">{list.total_device}</span>
                                                    </td>
                                                    <td className="product-price"><span className="amount">${list.total_price}</span></td>
                                                    <td className="product-remove">{list.created_at}</td>
                                                </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </form>
                            </div>
                        }
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default Dashboard
