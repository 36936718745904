import React from 'react';
import { Link } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';

function Blog() {
    return (
        <div>
            <Header /> 
            <main>
                <div className="breadcrumb-area pt-45 pb-45" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h2>Blog</h2>
                                    </div>
                                    <ul className="breadcrumb-menu">
                                        <li><a href="#">Home</a></li>
                                        <li><span>Blog</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blog-area pt-105 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="blog-wrapper mb-55">
                                    <div className="blog-img pos-rel">
                                        <Link to="/blog-detail/1"><img src="/front/assets/img/blog/01.jpg" alt="" /></Link>
                                    </div>
                                    <div className="blog-text">
                                        <div className="blog-meta">
                                            <span><i className="far fa-calendar-alt"></i> <a href="#">25 Aug 2020</a></span>
                                        </div>
                                        <h4>
                                            <Link to="/blog-detail/1">Mirage Deep Dive Under anding Timin Response</Link>
                                        </h4>
                                        <p>Sedut perspiciatis unde omnis natus error sit voluptatem accusantium </p>
                                        <div className="b-button gray-b-button">
                                            <Link to="/blog-detail/1">read more <i className="far fa-plus"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="blog-wrapper mb-55">
                                    <div className="blog-img pos-rel">
                                        <Link to="/blog-detail/1"><img src="/front/assets/img/blog/01.jpg" alt="" /></Link>
                                    </div>
                                    <div className="blog-text">
                                        <div className="blog-meta">
                                            <span><i className="far fa-calendar-alt"></i> <a href="#">25 Aug 2020</a></span>
                                        </div>
                                        <h4>
                                            <Link to="/blog-detail/1">Mirage Deep Dive Under anding Timin Response</Link>
                                        </h4>
                                        <p>Sedut perspiciatis unde omnis natus error sit voluptatem accusantium </p>
                                        <div className="b-button gray-b-button">
                                            <Link to="/blog-detail/1">read more <i className="far fa-plus"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="blog-wrapper mb-55">
                                    <div className="blog-img pos-rel">
                                        <Link to="/blog-detail/1"><img src="/front/assets/img/blog/01.jpg" alt="" /></Link>
                                    </div>
                                    <div className="blog-text">
                                        <div className="blog-meta">
                                            <span><i className="far fa-calendar-alt"></i> <a href="#">25 Aug 2020</a></span>
                                        </div>
                                        <h4>
                                            <Link to="/blog-detail/1">Mirage Deep Dive Under anding Timin Response</Link>
                                        </h4>
                                        <p>Sedut perspiciatis unde omnis natus error sit voluptatem accusantium </p>
                                        <div className="b-button gray-b-button">
                                            <Link to="/blog-detail/1">read more <i className="far fa-plus"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="blog-wrapper mb-55">
                                    <div className="blog-img pos-rel">
                                        <Link to="/blog-detail/1"><img src="/front/assets/img/blog/01.jpg" alt="" /></Link>
                                    </div>
                                    <div className="blog-text">
                                        <div className="blog-meta">
                                            <span><i className="far fa-calendar-alt"></i> <a href="#">25 Aug 2020</a></span>
                                        </div>
                                        <h4>
                                            <Link to="/blog-detail/1">Mirage Deep Dive Under anding Timin Response</Link>
                                        </h4>
                                        <p>Sedut perspiciatis unde omnis natus error sit voluptatem accusantium </p>
                                        <div className="b-button gray-b-button">
                                            <Link to="/blog-detail/1">read more <i className="far fa-plus"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="blog-wrapper mb-55">
                                    <div className="blog-img pos-rel">
                                        <Link to="/blog-detail/1"><img src="/front/assets/img/blog/01.jpg" alt="" /></Link>
                                    </div>
                                    <div className="blog-text">
                                        <div className="blog-meta">
                                            <span><i className="far fa-calendar-alt"></i> <a href="#">25 Aug 2020</a></span>
                                        </div>
                                        <h4>
                                            <Link to="/blog-detail/1">Mirage Deep Dive Under anding Timin Response</Link>
                                        </h4>
                                        <p>Sedut perspiciatis unde omnis natus error sit voluptatem accusantium </p>
                                        <div className="b-button gray-b-button">
                                            <Link to="/blog-detail/1">read more <i className="far fa-plus"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="blog-wrapper mb-55">
                                    <div className="blog-img pos-rel">
                                        <Link to="/blog-detail/1"><img src="/front/assets/img/blog/01.jpg" alt="" /></Link>
                                    </div>
                                    <div className="blog-text">
                                        <div className="blog-meta">
                                            <span><i className="far fa-calendar-alt"></i> <a href="#">25 Aug 2020</a></span>
                                        </div>
                                        <h4>
                                            <Link to="/blog-detail/1">Mirage Deep Dive Under anding Timin Response</Link>
                                        </h4>
                                        <p>Sedut perspiciatis unde omnis natus error sit voluptatem accusantium </p>
                                        <div className="b-button gray-b-button">
                                            <Link to="/blog-detail/1">read more <i className="far fa-plus"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="basic-pagination mt-20 basic-pagination-2 text-center">
                                    <ul>
                                        <li><a href="#"><i className="far fa-angle-left"></i></a></li>
                                        <li className="active"><a href="#">1</a></li>
                                        <li><a href="#">2</a></li>
                                        <li><a href="#">3</a></li>
                                        <li><a href="#"><i className="far fa-angle-right"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Blog
