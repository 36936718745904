import React, {useEffect , useState} from 'react';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Loader from "react-loader-spinner";
import parse from 'html-react-parser';
import './term.css';

const URL = "https://floridamedicalsurplus.com/admin-panel/";

function Terms() {
    const [count , setCount] = useState(1);
    const [ProductList, setProductList] = useState();
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        fetch(
            URL+'api/setting',
          {
            method: "GET",
            headers: new Headers({
              Accept: "application/json"
            })
          }
        )
          .then(res => res.json())
          .then(response => {
              console.log(response);
            setProductList(response.data);
            setIsLoading(false);
          })
          .catch(error => console.log(error));
      }, []);

    return (
        <div>
            <Header /> 
            <main>
                <div className="breadcrumb-area pt-45 pb-45" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h1>Terms & Conditions</h1>
                                    </div>
                                    <ul className="breadcrumb-menu">
                                        <li><a href="#">Home</a></li>
                                        <li><span>Terms & Conditions</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blog-area pt-105 pb-70 grey-2-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 mb-30">
                                <div className="blog-details blog-standard">
                                    <div className="blog-wrapper">
                                    {/* {isLoading  ? <div className="w-100 text-center mt-5"><Loader
                                            type="TailSpin"
                                            color="#00BFFF"
                                            height={100}
                                            width={100}
                                        /></div> : 
                                            <div className="post-text  mb-20 widget">
                                                {parse(ProductList.terms)}
                                            </div>
                                        } */}

                                    <div class="entry-content content">


                                    <h3 className='text-center mb-2'><span style={{color: '#ff0000'}}>Please read this agreement to recognize your rights and obligations before you send your shipment.</span></h3>
                                    <p className='text-center mb-2'><span style={{color: '#ff0000'}}>All prices are subject to change because we are a medium and have no control over the market</span><br />
                                    If you agree with the terms and conditions set forth here, you may complete your order:<br />
                                    </p>
                                    <ul>
                                    <li>&nbsp;I am 18 years of age or older.</li>
                                    <li>Florida
Medical Surplus LLC sells excess diabetic test strips which it lawfully obtains and owns or possesses. If law enforcement determines that any items sold by Florida
Medical Surplus LLC are stolen property, Florida
Medical Surplus LLC will either not receive compensation or return any compensation for such stolen items.</li>
                                    <li>I am confirming that these diabetic supplies are not obtained by any government programs.</li>
                                    <li>Florida
Medical Surplus LLC does not buy international diabetic supplies. We only buy diabetic supplies with NDC codes.</li>
                                    <li>I will only send diabetic supplies that are not expired and will not expire for at least nine months from today for full price. Count the current month as “0” and the next month as “1”. For example, if the month is January (month “0”), then if your strips expired in 9 months, they would expire in October (month “9”)</li>
                                    <li>The price that you will be paid is what you were invoiced at the time of order. Prices of products may change due to supply and demand; we cannot alter an invoice due to price fluctuations.</li>
                                    <li>I understand Florida
Medical Surplus LLC <span style={{color: '#ff0000'}}>may or may not </span>pay a reduced price for boxes with expiration dates that are less than 8 months away. Please refer to specific product descriptions on the website not all products are taken with short dates.</li>
                                    <li>I understand Florida
Medical Surplus LLC <span style={{color: '#ff0000'}}>may or may not</span> pay a reduced price for damaged products based on individual product descriptions on the website.</li>
                                    <li>I understand Florida
Medical Surplus LLC has the right to reject product that does not meet our standards, as well as product that is not listed on our website. These products will be donated or disposed of.<span style={{color: '#ff0000'}}> Florida
Medical Surplus LLC does not return any products sent in that do not meet our terms and conditions.</span> </li>
                                    <li>Florida
Medical Surplus LLC will not pay for diabetic supplies that are expired or too damaged upon receipt of delivery. If they receive ANY diabetic supplies that I sent and are not eligible for submission due to dates being too short, expired, too damaged, and cannot be considered slightly dinged,<span style={{color: '#ff0000'}}> the product in question will be donated and or disposed of. Florida
Medical Surplus LLC will not hold or return them to me.</span> </li>
                                    <li>My diabetic supplies are factory sealed and have never been opened (meaning they have not been resealed in ANY way).</li>
                                    <li>I understand that Florida
Medical Surplus LLC cannot reverse electronic payments once they have been sent out. Including, but not limited to, CashApp, Zelle, Google Pay, Venmo, bank wires, etc. If I provide an incorrect CashApp or Venmo tag, the wrong bank account information for wires, an incorrect email address, etc. it is not the Payor’s responsibility to refund my payment. If this occurs, I must contact the corresponding payment entity for a solution.</li>
                                    <li>I understand that Florida
Medical Surplus LLC is not responsible for any errors on checks due to the misspelling of names, incorrect addresses, etc. due to typos on the order form I filled out and submitted. If my check needs to be canceled and reprinted for this reason, I will be responsible for the cancelled check fee of $30.00.</li>
                                    <li>I understand check payment delivery is dependent upon the USPS mail speed and holidays.&nbsp;</li>
                                    <li>If payment in the form of a check has not been received after 21 business days, we will put a stop payment in place once we have verified that the payment was not rendered. After the check is canceled an alternative payment method can be used if you choose to do so.<span style={{color: '#ff0000'}}> Florida
Medical Surplus LLC will not cancel any check before the 21-day waiting period.</span></li>
                                    <li><span style={{color: '#ff0000'}}>I understand if I have a dispute about payment or an order discrepancy, I must contact Florida
Medical Surplus LLC within 30 days. After 30 days all order outcomes and payments are final.</span></li>
                                    </ul>


                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Terms
