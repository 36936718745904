import React, {useEffect , useState} from 'react';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Loader from "react-loader-spinner";
import parse from 'html-react-parser';
import './term.css';

const URL = "https://floridamedicalsurplus.com/admin-panel/";

function Damage() {
    const [count , setCount] = useState(1);
    const [ProductList, setProductList] = useState();
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        fetch(
            URL+'api/setting',
          {
            method: "GET",
            headers: new Headers({
              Accept: "application/json"
            })
          }
        )
          .then(res => res.json())
          .then(response => {
              console.log(response);
            setProductList(response.data);
            setIsLoading(false);
          })
          .catch(error => console.log(error));
      }, []);

    return (
        <div>
            <Header /> 
            <main>
                <div className="breadcrumb-area pt-45 pb-45" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h1>DAMAGE GUIDELINES</h1>
                                    </div>
                                    <ul className="breadcrumb-menu">
                                        <li><a href="#">Home</a></li>
                                        <li><span>DAMAGE GUIDELINESs</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blog-area pt-105 pb-70 grey-2-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 mb-30">
                                <div className="blog-details blog-standard">
                                <div className="elementor-container elementor-column-gap-default">
                                    <div className="elementor-row">
                                    <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-3ed6e24 exad-glass-effect-no exad-sticky-section-no" data-id="3ed6e24" data-element_type="column">
                                        <div className="elementor-column-wrap elementor-element-populated">
                                        <div className="elementor-widget-wrap">
                                            <div className="elementor-element elementor-element-73fbc1f exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor" data-id="73fbc1f" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-text-editor elementor-clearfix">
                                                <p className="">Please take a minute and read our damage guidelines below to see if your items meet our criteria.</p><p>When selling to Florida
Medical Surplus LLC, we want to make it as clear as possible to our clients on how we are grading your items. Any boxes that arrive to us damaged will be marked down 50%.</p>					</div>
                                            </div>
                                            </div>
                                            <div className="elementor-element elementor-element-c23d112 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-heading" data-id="c23d112" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h3 className="elementor-heading-title elementor-size-default">How To Avoid Damage
                                                </h3>		</div>
                                            </div>
                                            <div className="elementor-element elementor-element-2b16a01 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor" data-id="2b16a01" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-text-editor elementor-clearfix">
                                                <p>The most common reason boxes arrive to us damaged is because clients are trying to remove their pharmacy labels by themselves! Please NEVER do this as it can cause extreme damage to the boxes very easily. We remove them professionally and carefully to avoid any damage. You can always contact us at 239-946-4352 with any questions before shipping.</p>					</div>
                                            </div>
                                            </div>
                                            <div className="elementor-element elementor-element-6b3eb0d exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-heading" data-id="6b3eb0d" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h3 className="elementor-heading-title elementor-size-default">What Is Considered Acceptable Damage?
                                                </h3>		</div>
                                            </div>
                                            <div className="elementor-element elementor-element-607d9c7 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor" data-id="607d9c7" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-text-editor elementor-clearfix">
                                                First things first, the expiration date cannot be smeared and the seals must ALWAYS be in tact or we cannot accept them at all.  Acceptable damage would be considered tears, ink, scrapes, dents, creases, crushing or bent boxes. It’s okay if your box has tape on them, just don’t remove them yourself or you could damage your boxes.
                                                </div>
                                            </div>
                                            </div>
                                            <div className="elementor-element elementor-element-9ac7e5e exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-heading mt-3" data-id="9ac7e5e" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h3 className="elementor-heading-title elementor-size-default">What Is Considered Unacceptable Damage?
                                                </h3>		</div>
                                            </div>
                                            <div className="elementor-element elementor-element-640d443 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-text-editor" data-id="640d443" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-text-editor elementor-clearfix">
                                                Boxes that have holes in them or seals broken are the most common reasons. Others include the expiration date being smeared or unreadable, or the boxes having slashes through them. We also will not accept any items that aren’t listed on our website or if they don’t fall between our expiration date guidelines.
                                                </div>
                                            </div>
                                            </div>
                                            <div className="elementor-element elementor-element-447bd58 exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-heading mt-5" data-id="447bd58" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container text-center">
                                                <h3 className="elementor-heading-title elementor-size-default">Acceptable</h3>		</div>
                                            </div>
                                            <div className="elementor-element elementor-element-c00b11c gallery-spacing-custom exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-image-gallery" data-id="c00b11c" data-element_type="widget" data-widget_type="image-gallery.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-image-gallery">
                                                <div id="gallery-1" className="gallery galleryid-1004 gallery-columns-3 gallery-size-full row"><figure className="gallery-item  col-4">
                                                    <div className="gallery-icon portrait">
                                                        <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="c00b11c" data-elementor-lightbox-title="khtjkmhqhc4mi3atofmr" e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MTAxMSwidXJsIjoiaHR0cHM6XC9cL2Nhc2g0c3RyaXBzbm93LmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyMVwvMDdcL2todGprbWhxaGM0bWkzYXRvZm1yLXNjYWxlZC5qcGciLCJzbGlkZXNob3ciOiJjMDBiMTFjIn0%3D" href="#"><img  src="https://cash4stripsnow.com/wp-content/uploads/2021/07/khtjkmhqhc4mi3atofmr-scaled.jpg" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://cash4stripsnow.com/wp-content/uploads/2021/07/khtjkmhqhc4mi3atofmr-scaled.jpg 1244w, https://cash4stripsnow.com/wp-content/uploads/2021/07/khtjkmhqhc4mi3atofmr-scaled-300x617.jpg 300w, https://cash4stripsnow.com/wp-content/uploads/2021/07/khtjkmhqhc4mi3atofmr-146x300.jpg 146w, https://cash4stripsnow.com/wp-content/uploads/2021/07/khtjkmhqhc4mi3atofmr-498x1024.jpg 498w, https://cash4stripsnow.com/wp-content/uploads/2021/07/khtjkmhqhc4mi3atofmr-768x1580.jpg 768w, https://cash4stripsnow.com/wp-content/uploads/2021/07/khtjkmhqhc4mi3atofmr-747x1536.jpg 747w, https://cash4stripsnow.com/wp-content/uploads/2021/07/khtjkmhqhc4mi3atofmr-1000x2057.jpg 1000w"  /></a>
                                                    </div></figure><figure className="gallery-item   col-4">
                                                    <div className="gallery-icon portrait">
                                                        <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="c00b11c" data-elementor-lightbox-title="e7effplhbhpaqijzwrk9" e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MTAxNCwidXJsIjoiaHR0cHM6XC9cL2Nhc2g0c3RyaXBzbm93LmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyMVwvMDdcL2U3ZWZmcGxoYmhwYXFpanp3cms5LXNjYWxlZC5qcGciLCJzbGlkZXNob3ciOiJjMDBiMTFjIn0%3D" href="#"><img  src="https://cash4stripsnow.com/wp-content/uploads/2021/07/e7effplhbhpaqijzwrk9-scaled.jpg" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://cash4stripsnow.com/wp-content/uploads/2021/07/e7effplhbhpaqijzwrk9-scaled.jpg 1244w, https://cash4stripsnow.com/wp-content/uploads/2021/07/e7effplhbhpaqijzwrk9-scaled-300x617.jpg 300w, https://cash4stripsnow.com/wp-content/uploads/2021/07/e7effplhbhpaqijzwrk9-146x300.jpg 146w, https://cash4stripsnow.com/wp-content/uploads/2021/07/e7effplhbhpaqijzwrk9-498x1024.jpg 498w, https://cash4stripsnow.com/wp-content/uploads/2021/07/e7effplhbhpaqijzwrk9-768x1580.jpg 768w, https://cash4stripsnow.com/wp-content/uploads/2021/07/e7effplhbhpaqijzwrk9-747x1536.jpg 747w, https://cash4stripsnow.com/wp-content/uploads/2021/07/e7effplhbhpaqijzwrk9-1000x2057.jpg 1000w"  /></a>
                                                    </div></figure><figure className="gallery-item   col-4">
                                                    <div className="gallery-icon portrait">
                                                        <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="c00b11c" data-elementor-lightbox-title="nrbej9qey0zrnyhvzhnh" e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MTAxNSwidXJsIjoiaHR0cHM6XC9cL2Nhc2g0c3RyaXBzbm93LmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyMVwvMDdcL25yYmVqOXFleTB6cm55aHZ6aG5oLXNjYWxlZC5qcGciLCJzbGlkZXNob3ciOiJjMDBiMTFjIn0%3D" href="#"><img  src="https://cash4stripsnow.com/wp-content/uploads/2021/07/nrbej9qey0zrnyhvzhnh-scaled.jpg" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://cash4stripsnow.com/wp-content/uploads/2021/07/nrbej9qey0zrnyhvzhnh-scaled.jpg 1244w, https://cash4stripsnow.com/wp-content/uploads/2021/07/nrbej9qey0zrnyhvzhnh-scaled-300x617.jpg 300w, https://cash4stripsnow.com/wp-content/uploads/2021/07/nrbej9qey0zrnyhvzhnh-146x300.jpg 146w, https://cash4stripsnow.com/wp-content/uploads/2021/07/nrbej9qey0zrnyhvzhnh-498x1024.jpg 498w, https://cash4stripsnow.com/wp-content/uploads/2021/07/nrbej9qey0zrnyhvzhnh-768x1580.jpg 768w, https://cash4stripsnow.com/wp-content/uploads/2021/07/nrbej9qey0zrnyhvzhnh-747x1536.jpg 747w, https://cash4stripsnow.com/wp-content/uploads/2021/07/nrbej9qey0zrnyhvzhnh-1000x2057.jpg 1000w"  /></a>
                                                    </div></figure>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="elementor-element elementor-element-0f5aa8a exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-heading mt-5" data-id="0f5aa8a" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container text-center">
                                                <h3 className="elementor-heading-title elementor-size-default ">Unacceptable
                                                </h3>		</div>
                                            </div>
                                            <div className="elementor-element elementor-element-1a1cb43 gallery-spacing-custom exad-sticky-section-no exad-glass-effect-no elementor-widget elementor-widget-image-gallery" data-id="1a1cb43" data-element_type="widget" data-widget_type="image-gallery.default">
                                            <div className="elementor-widget-container">
                                                <div className="elementor-image-gallery">
                                                <div id="gallery-2" className="gallery galleryid-1004 gallery-columns-4 gallery-size-full row"><figure className="gallery-item   col-3">
                                                    <div className="gallery-icon portrait">
                                                        <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="1a1cb43" data-elementor-lightbox-title="bfq4nfjjrm4umvf2lnvp" e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MTAwNSwidXJsIjoiaHR0cHM6XC9cL2Nhc2g0c3RyaXBzbm93LmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyMVwvMDdcL2JmcTRuZmpqcm00dW12ZjJsbnZwLXNjYWxlZC5qcGciLCJzbGlkZXNob3ciOiIxYTFjYjQzIn0%3D" href="#"><img  src="https://cash4stripsnow.com/wp-content/uploads/2021/07/bfq4nfjjrm4umvf2lnvp-scaled.jpg" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://cash4stripsnow.com/wp-content/uploads/2021/07/bfq4nfjjrm4umvf2lnvp-scaled.jpg 1244w, https://cash4stripsnow.com/wp-content/uploads/2021/07/bfq4nfjjrm4umvf2lnvp-scaled-300x617.jpg 300w, https://cash4stripsnow.com/wp-content/uploads/2021/07/bfq4nfjjrm4umvf2lnvp-146x300.jpg 146w, https://cash4stripsnow.com/wp-content/uploads/2021/07/bfq4nfjjrm4umvf2lnvp-498x1024.jpg 498w, https://cash4stripsnow.com/wp-content/uploads/2021/07/bfq4nfjjrm4umvf2lnvp-768x1580.jpg 768w, https://cash4stripsnow.com/wp-content/uploads/2021/07/bfq4nfjjrm4umvf2lnvp-747x1536.jpg 747w, https://cash4stripsnow.com/wp-content/uploads/2021/07/bfq4nfjjrm4umvf2lnvp-1000x2057.jpg 1000w"  /></a>
                                                    </div></figure><figure className="gallery-item   col-3">
                                                    <div className="gallery-icon portrait">
                                                        <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="1a1cb43" data-elementor-lightbox-title="ioojjax031j6ljdjkz5g" e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MTAwNiwidXJsIjoiaHR0cHM6XC9cL2Nhc2g0c3RyaXBzbm93LmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyMVwvMDdcL2lvb2pqYXgwMzFqNmxqZGprejVnLXNjYWxlZC5qcGciLCJzbGlkZXNob3ciOiIxYTFjYjQzIn0%3D" href="#"><img  src="https://cash4stripsnow.com/wp-content/uploads/2021/07/ioojjax031j6ljdjkz5g-scaled.jpg" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://cash4stripsnow.com/wp-content/uploads/2021/07/ioojjax031j6ljdjkz5g-scaled.jpg 1244w, https://cash4stripsnow.com/wp-content/uploads/2021/07/ioojjax031j6ljdjkz5g-scaled-300x617.jpg 300w, https://cash4stripsnow.com/wp-content/uploads/2021/07/ioojjax031j6ljdjkz5g-146x300.jpg 146w, https://cash4stripsnow.com/wp-content/uploads/2021/07/ioojjax031j6ljdjkz5g-498x1024.jpg 498w, https://cash4stripsnow.com/wp-content/uploads/2021/07/ioojjax031j6ljdjkz5g-768x1580.jpg 768w, https://cash4stripsnow.com/wp-content/uploads/2021/07/ioojjax031j6ljdjkz5g-747x1536.jpg 747w, https://cash4stripsnow.com/wp-content/uploads/2021/07/ioojjax031j6ljdjkz5g-1000x2057.jpg 1000w"  /></a>
                                                    </div></figure><figure className="gallery-item   col-3">
                                                    <div className="gallery-icon portrait">
                                                        <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="1a1cb43" data-elementor-lightbox-title="vvpf4l0qagqme2q4pmap" e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MTAwOCwidXJsIjoiaHR0cHM6XC9cL2Nhc2g0c3RyaXBzbm93LmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyMVwvMDdcL3Z2cGY0bDBxYWdxbWUycTRwbWFwLXNjYWxlZC5qcGciLCJzbGlkZXNob3ciOiIxYTFjYjQzIn0%3D" href="#"><img  src="https://cash4stripsnow.com/wp-content/uploads/2021/07/vvpf4l0qagqme2q4pmap-scaled.jpg" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://cash4stripsnow.com/wp-content/uploads/2021/07/vvpf4l0qagqme2q4pmap-scaled.jpg 1244w, https://cash4stripsnow.com/wp-content/uploads/2021/07/vvpf4l0qagqme2q4pmap-scaled-300x617.jpg 300w, https://cash4stripsnow.com/wp-content/uploads/2021/07/vvpf4l0qagqme2q4pmap-146x300.jpg 146w, https://cash4stripsnow.com/wp-content/uploads/2021/07/vvpf4l0qagqme2q4pmap-498x1024.jpg 498w, https://cash4stripsnow.com/wp-content/uploads/2021/07/vvpf4l0qagqme2q4pmap-768x1580.jpg 768w, https://cash4stripsnow.com/wp-content/uploads/2021/07/vvpf4l0qagqme2q4pmap-747x1536.jpg 747w, https://cash4stripsnow.com/wp-content/uploads/2021/07/vvpf4l0qagqme2q4pmap-1000x2057.jpg 1000w"  /></a>
                                                    </div></figure><figure className="gallery-item col-3 ">
                                                    <div className="gallery-icon portrait">
                                                        <a data-elementor-open-lightbox="yes" data-elementor-lightbox-slideshow="1a1cb43" data-elementor-lightbox-title="iaib96bwr8z05aswxuon" e-action-hash="#elementor-action%3Aaction%3Dlightbox%26settings%3DeyJpZCI6MTAxMCwidXJsIjoiaHR0cHM6XC9cL2Nhc2g0c3RyaXBzbm93LmNvbVwvd3AtY29udGVudFwvdXBsb2Fkc1wvMjAyMVwvMDdcL2lhaWI5NmJ3cjh6MDVhc3d4dW9uLXNjYWxlZC5qcGciLCJzbGlkZXNob3ciOiIxYTFjYjQzIn0%3D" href="#"><img  src="https://cash4stripsnow.com/wp-content/uploads/2021/07/iaib96bwr8z05aswxuon-scaled.jpg" className="attachment-full size-full" alt="" loading="lazy" srcSet="https://cash4stripsnow.com/wp-content/uploads/2021/07/iaib96bwr8z05aswxuon-scaled.jpg 1244w, https://cash4stripsnow.com/wp-content/uploads/2021/07/iaib96bwr8z05aswxuon-scaled-300x617.jpg 300w, https://cash4stripsnow.com/wp-content/uploads/2021/07/iaib96bwr8z05aswxuon-146x300.jpg 146w, https://cash4stripsnow.com/wp-content/uploads/2021/07/iaib96bwr8z05aswxuon-498x1024.jpg 498w, https://cash4stripsnow.com/wp-content/uploads/2021/07/iaib96bwr8z05aswxuon-768x1580.jpg 768w, https://cash4stripsnow.com/wp-content/uploads/2021/07/iaib96bwr8z05aswxuon-747x1536.jpg 747w, https://cash4stripsnow.com/wp-content/uploads/2021/07/iaib96bwr8z05aswxuon-1000x2057.jpg 1000w"  /></a>
                                                    </div></figure>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Damage
