import React, {useEffect , useState} from 'react';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Loader from "react-loader-spinner";
import axios from "axios";

const URL = "https://floridamedicalsurplus.com/admin-panel/";

function Contact() {

    const [name, setName] = useState('');
    const [userError, setuserError] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    function handleChange(e)  {
        e.preventDefault();
        setName(e.target.value);
        setuserError('');
    };
    function handleEmail(e)  {
        e.preventDefault();
        setEmail(e.target.value);
        setEmailError('');
    };
    function handlePassword(e)  {
        e.preventDefault();
        setPhone(e.target.value);
        setPhoneError('');
    };
    function handleConfirmPassword(e)  {
        e.preventDefault();
        setMessage(e.target.value);
        setMessageError('');
    };

    function onSubmitHandler(e){
        e.preventDefault();
        setIsLoading(true);
        const signupData = { name:name, email:email,phone:phone,message:message }
        axios
            .post(URL+'api/contact', signupData)
            .then((response) => {
                
                console.log(response.data);
                if(response.data.error) {
                    if(response.data.error.name) {
                        response.data.error.name.map((ERROR, index) => (
                            setuserError(ERROR)
                        ))
                    }
                    if(response.data.error.email) {
                        response.data.error.email.map((ERROR, index) => (
                            setEmailError(ERROR)
                        ))
                    }
                    if(response.data.error.phone) {
                        response.data.error.phone.map((ERROR, index) => (
                            setPhoneError(ERROR)
                        ))
                    }
                    if(response.data.error.message) {
                        response.data.error.message.map((ERROR, index) => (
                            setMessageError(ERROR)
                        ))
                    }
                }

                if(response.data.success) {
                    alert('Your Message Sent Successfully. We will contact you shortly!');
                    setName('');
                    setEmail('');
                    setPhone('');
                    setMessage('');
                }
                    
                setIsLoading(false);
                

                if (!response.status) {
                    console.log(response.data.message)
                    setTimeout(() => {
                        console.log("error")
                    }, 2000);
                }
            });
        };

    return (
        <div>
            <Header /> 
            <main>
                <div className="breadcrumb-area pt-45 pb-45" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h1>Contact Us</h1>
                                    </div>
                                    <ul className="breadcrumb-menu">
                                        <li><a href="#">Home</a></li>
                                        <li><span>Contact Us</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact-area pt-100 pb-100 grey-2-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 offset-lg-3 offset-xl-3">
                                <div className="section-title text-center mb-65 ">
                                    <h2 className='text-dark'>Contact Florida Medical Surplus </h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <form id="contacts-form" className="contacts-form" action="#">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12">
                                            <div className="contacts-icon contactss-name">
                                                <input type="text" className="mb-0" placeholder="Your Full Name " value={name} onChange={handleChange}/>
                                                <div className="product-text mt-0 mb-3">
                                                    {userError ? <span><span className="error">{userError}</span><br/></span> :''}
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="col-lg-4 col-md-12">
                                            <div className="contacts-icon contactss-email">
                                                <input type="email" className="mb-0" placeholder="Your Email Address" value={email} onChange={handleEmail}/>
                                                <div className="product-text mt-0 mb-3">
                                                    {emailError ? <span><span className="error">{emailError}</span><br/></span> :''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12">
                                            <div className="contacts-icon contactss-website">
                                                <input type="text" className="mb-0" placeholder="Your Phone" value={phone} onChange={handlePassword} />
                                                <div className="product-text mt-0 mb-3">
                                                    {phoneError ? <span><span className="error">{phoneError}</span><br/></span> :''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contacts-icon contactss-message">
                                                <textarea name="comments" className="mb-0" id="comments" cols="30" rows="10" placeholder="Your Comments...."  onChange={handleConfirmPassword} >{message}</textarea>
                                                <div className="product-text mt-0 mb-3">
                                                    {messageError ? <span><span className="error">{messageError}</span><br/></span> :''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contacts-form-button text-center">
                                            {isLoading  ? <div className="w-100 text-center"><Loader
                                                type="TailSpin"
                                                color="#00BFFF"
                                                height={50}
                                                width={100}
                                            /></div> :
                                                <button className="c-btn" onClick={onSubmitHandler}>
                                                    send message <i className="far fa-plus"></i>
                                                </button>
                                            }   
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Contact
