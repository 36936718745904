import React , { useState, useEffect } from 'react'
import {Link} from "react-router-dom";
import Loader from "react-loader-spinner";

const URL = "https://floridamedicalsurplus.com/admin-panel/";

function Product_Grid(props) {
    console.log(props.brand);

    const [commitHistory, setCommitHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch(
            URL+'api/product-list/'+props.brand,
          {
            method: "GET",
            headers: new Headers({
              Accept: "application/json"
            })
          }
        )
          .then(res => res.json())
          .then(response => {
              console.log(response);
            setCommitHistory(response.data);
            setIsLoading(false);
          })
          .catch(error => console.log(error));
      }, []);

    return (
        <div className="row">
            {isLoading  ? <div className="w-100 text-center mt-5"><Loader
                    type="TailSpin"
                    color="#00BFFF"
                    height={100}
                    width={100}
                /></div> :
            
            commitHistory.map((list, index) => (
            <div className="col-xl-4 cl-lg-4 col-md-6" key={index}>
                <div className="product-wrapper text-center basic-login p-3">
                    <div className="product-img pos-rel">
                        <Link to={'/product-detail/'+ list.slug}> 
                            <img src={URL +'public/'+ list.device_image} alt={list.device_name} />
                        </Link>
                        <div className="product-action">
                            <Link  to={'/product-detail/'+ list.slug} className="c-btn">
                                View Product Detail <i className="far fa-arrow-right"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="product-text mt-0">
                        <h4>
                            <Link  to={'/product-detail/'+ list.slug}>
                            {list.device_name}
                            </Link>    
                        </h4>
                    </div>
                </div>
            </div>
            ))}
        </div>
    )
}

export default Product_Grid
