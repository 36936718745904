import React, {useEffect , useState} from 'react'
import { Link } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Loader from "react-loader-spinner";
import axios from "axios";

const URL = "https://floridamedicalsurplus.com/admin-panel/";
const login = localStorage.getItem("isLoggedIn");

function Cart() {

    const [count , setCount] = useState(1);
    const [ProductList, setProductList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    var totalPrice = 0;
    useEffect(() => {
        fetch(
            URL+'api/cart',
          {
            method: "GET",
            headers: new Headers({
              Accept: "application/json"
            })
          }
        )
          .then(res => res.json())
          .then(response => {
              console.log(response);
            setProductList(response.data);
            setIsLoading(false);
          })
          .catch(error => console.log(error));
      }, []);
      

      function getCart() {
            fetch(
                URL+'api/cart',
            {
                method: "GET",
                headers: new Headers({
                Accept: "application/json"
                })
            }
            )
            .then(res => res.json())
            .then(response => {
                console.log(response);
                setProductList(response.data);
                setIsLoading(false);
            })
            .catch(error => console.log(error));
      }

    function update_qty(info) {
        setIsLoading(true);
        axios.post(URL+'api/update_cart', info)
            .then(response => {
                getCart();
            })
            .catch(error => {
                setIsLoading(false);
                console.error('There was an error!', error);
            });
    
    }

    const decrement = (qty,id) => {
        const info = { qty: qty - 1,id:id };
        update_qty(info)
    };

    const increment = (qty,id) => {
        const info = { qty: qty + 1,id:id };
        update_qty(info)
    };

    const removeCart = (id) => {
        
        const info = {id:id };
        setIsLoading(true);
        axios.post(URL+'api/remove-from-cart', info)
            .then(response => {
                getCart();
            })
            .catch(error => {
                setIsLoading(false);
                console.error('There was an error!', error);
            });
        
    };


    return (
        <div>
            <Header /> 
            <main>
                <div className="breadcrumb-area pt-45 pb-45" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h1>Cart</h1>
                                    </div>
                                    <ul className="breadcrumb-menu">
                                        <li><a href="#">Home</a></li>
                                        <li><span>Cart</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading  ? <div className="w-100 text-center mt-5"><Loader
                        type="TailSpin"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    /></div> : 
                    <div>
                        <section className="cart-area pt-100 pb-100">
                            <div className="container">
                                <div className="row">
                                    <div className="col-12">
                                        <form action="#">
                                            <div className="table-content table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="product-thumbnail">Images</th>
                                                            <th className="cart-product-name">Product</th>
                                                            <th className="product-price">Unit Price</th>
                                                            <th className="product-price">Quantity</th>
                                                            <th className="product-subtotal">Total</th>
                                                            <th className="product-remove">Remove</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {ProductList.map((list, index) => (
                                                        <tr>
                                                           
                                                            <td className="product-thumbnail"><a href="#"><img src={URL +'public/'+ list.sell_device.device_image} alt="" /></a></td>
                                                            <td className="product-name">
                                                                <Link  to={'/product-detail/'+ list.sell_device.slug}>
                                                                {list.sell_device.device_name}
                                                                </Link>  
                                                                <ul>
                                                                    <li>
                                                                        <div className="pro-02-list-text">
                                                                            {list.sell_expire ? 
                                                                            <span className="mr-3"><b>Expiration:</b> {list.sell_expire.name}</span> :''}
                                                                            {list.sell_condition ? 
                                                                            <span className="mr-3"><b>Condition:</b> {list.sell_condition.name}</span> :''}
                                                                        </div>
                                                                    </li>
                                                                    
                                                                </ul>
                                                            </td>
                                                            <td className="product-price"><span className="amount">${parseInt(list.sell_device.base_price) + parseInt(list.sell_condition.price) + parseInt(list.sell_expire.price)}</span></td>
                                                            <td className="product-price">
                                                            <div className="plus-minus">
                                                                <div className="cart-plus-minus">
                                                                    <input type="text" value={list.qty} />
                                                                    <div className="dec qtybutton" onClick={() => decrement(list.qty,list.id)}>-</div>
                                                                    <div className="inc qtybutton" onClick={() => increment(list.qty,list.id)}>+</div>
                                                                </div>
                                                            </div>
                                                            </td>
                                                            <td className="product-subtotal"><span className="amount">${ (parseInt(list.sell_device.base_price) + parseInt(list.sell_condition.price) + parseInt(list.sell_expire.price)) * list.qty} 
                                                            <span className="d-none">{totalPrice += (parseInt(list.sell_device.base_price) + parseInt(list.sell_condition.price) + parseInt(list.sell_expire.price)) * list.qty }</span>
                                                            </span></td>
                                                            <td className="product-remove"><a href="javascript:;"
                                                            
                                                            onClick={() => {
                                                                const confirmBox = window.confirm(
                                                                  "Do you really want to delete this product from cart?"
                                                                )
                                                                if (confirmBox === true) {
                                                                    removeCart(list.id)
                                                                }
                                                              }} ><i className="fa fa-times"></i></a></td>
                                                        </tr>
                                                        ))}
                                                        {/* <tr>
                                                            <td className="product-thumbnail"><a href="#"><img src="/front/assets/img/products/02.jpg" alt="" /></a></td>
                                                            <td className="product-name"><a href="#">Sujon Chair Set</a></td>
                                                            <td className="product-price"><span className="amount">$120.50</span></td>
                                                            <td className="product-quantity">
                                                                <div className="cart-plus-minus"><input type="text" value="1" /><div className="dec qtybutton">-</div><div className="inc qtybutton">+</div></div>
                                                            </td>
                                                            <td className="product-subtotal"><span className="amount">$120.50</span></td>
                                                            <td className="product-remove"><a href="#"><i className="fa fa-times"></i></a></td>
                                                        </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 ml-auto">
                                                    <div className="cart-page-total">
                                                        <h2>Cart totals</h2>
                                                        <ul className="mb-20">
                                                            <li>Total <span>${totalPrice}</span></li>
                                                        </ul>
                                                        {login ? 
                                                            <Link to="/checkout" className="c-btn theme-btn"> Proceed to checkout</Link> 
                                                            : 
                                                            <><Link to="/login" className="c-btn theme-btn"> Login & Proceed to checkout</Link><Link to="/checkout" className="c-btn theme-btn"> Checkout As Guest</Link></> 
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                }
             </main>
            <Footer />
        </div>
    )
}

export default Cart
