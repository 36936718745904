import React, {useEffect , useState} from 'react'
import { Link , useHistory  } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Loader from "react-loader-spinner";
import axios from "axios";

const URL = "https://floridamedicalsurplus.com/admin-panel/";

function Login() {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();


    function handleEmail(e)  {
        e.preventDefault();
        setEmail(e.target.value);
        setEmailError('');
    };
    function handlePassword(e)  {
        e.preventDefault();
        setPassword(e.target.value);
        setPasswordError('');
    };

    function onSubmitHandler(e){
        e.preventDefault();
        setIsLoading(true);
        const signupData = {email:email,password:password }
        axios
            .post(URL+'api/login', signupData)
            .then((response) => {
                
                console.log(response.data);
                if(response.data.error) {
                    if(response.data.error.email) {
                        response.data.error.email.map((ERROR, index) => (
                            setEmailError(ERROR)
                        ))
                    }
                    if(response.data.error.password) {
                        response.data.error.password.map((ERROR, index) => (
                            setPasswordError(ERROR)
                        ))
                    }
                    alert('Please FIx Following Errors!')
                }

                if(response.data.success) {
                   
                    localStorage.setItem("isLoggedIn", true);
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("user_id", response.data.user_id);
                    
                    fetch(
                        URL+'api/cart',
                      {
                        method: "GET",
                        headers: new Headers({
                          Accept: "application/json"
                        })
                      }
                    )
                      .then(res => res.json())
                      .then(response => {
                          console.log(response);
                          setIsLoading(false);
                          alert('Logged In Successfully!');
                          if(response.count > 0) {
                                history.push("/checkout");
                          } else {
                                history.push("/dashboard");
                          }
                      })
                      .catch(error => console.log(error));
                } else {
                    setIsLoading(false);
                    alert(response.data.message)
                }
                
                

                if (!response.status) {
                    console.log(response.data.message)
                    setTimeout(() => {
                        console.log("error")
                    }, 2000);
                }
            });
    };

    return (
        <div>
            <Header /> 
            <main>
                <div className="breadcrumb-area pt-45 pb-45" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h1>Login</h1>
                                    </div>
                                    <ul className="breadcrumb-menu">
                                        <li><a href="#">Home</a></li>
                                        <li><span>Login</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="login-area pt-100 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="basic-login">
                                    <h2 className="text-center mb-60">Login From Here</h2>
                                    <div className="product-text">
                                        {emailError ? <span><span className="error">{emailError}</span><br/></span>: ''}
                                        {passwordError ? <span><span className="error">{passwordError}</span><br/></span> :''}
                                    </div>
                                    <form action="#">
                                        <label for="name">Email Address <span>**</span></label>
                                        <input id="name" type="email" placeholder="Enter Username or Email address..." value={email} onChange={handleEmail} />
                                        <label for="pass">Password <span>**</span></label>
                                        <input id="pass" type="password" placeholder="Enter password..." value={password} onChange={handlePassword}/>
                                        {/* <div className="login-action mb-20 fix">
                                            <span className="log-rem f-left">
                                                <input id="remember" type="checkbox" />
                                                <label for="remember">Remember me!</label>
                                            </span>
                                            <span className="forgot-login f-right">
                                                <a href="#">Lost your password?</a>
                                            </span>
                                        </div> */}
                                        {isLoading  ? <div className="w-100 text-center"><Loader
                                            type="TailSpin"
                                            color="#00BFFF"
                                            height={50}
                                            width={100}
                                        /></div> :
                                            <button className="c-btn theme-btn-2 w-100"  onClick={onSubmitHandler}>Login Now</button>
                                        }
                                        <div className="or-divide"><span>or</span></div>
                                        {/* <button className="c-btn theme-btn w-100">Register Now</button> */}
                                        <Link to="/register"  className="c-btn theme-btn w-100">Register Now</Link>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
             </main>
            <Footer />
        </div>
    )
}

export default Login
