import React, {useEffect , useState} from 'react';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Loader from "react-loader-spinner";
import parse from 'html-react-parser';
const URL = "https://floridamedicalsurplus.com/admin-panel/";

function Privacy() {
    const [count , setCount] = useState(1);
    const [ProductList, setProductList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        fetch(
            URL+'api/setting',
          {
            method: "GET",
            headers: new Headers({
              Accept: "application/json"
            })
          }
        )
          .then(res => res.json())
          .then(response => {
              console.log(response);
            setProductList(response.data);
            setIsLoading(false);
          })
          .catch(error => console.log(error));
      }, []);

    return (
        <div>
            <Header /> 
            <main>
                <div className="breadcrumb-area pt-45 pb-45" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h1>Privacy Policy</h1>
                                    </div>
                                    <ul className="breadcrumb-menu">
                                        <li><a href="#">Home</a></li>
                                        <li><span>Privacy Policy</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="blog-area pt-105 pb-70 grey-2-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 mb-30">
                                <div className="blog-details blog-standard">
                                    <div className="blog-wrapper">
                                    {isLoading  ? <div className="w-100 text-center mt-5"><Loader
                                            type="TailSpin"
                                            color="#00BFFF"
                                            height={100}
                                            width={100}
                                        /></div> : 
                                            <div className="post-text  mb-20 widget">
                                                {parse(ProductList.privacy)}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Privacy
