import React from 'react';
import Slider from "react-slick";
import {Link} from "react-router-dom";

function HomeSlider() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        arrows: false,
      };
    return (
        <section className="hero-area">
            <Slider  className="hero-slider" {...settings} style={{background: "#662D91"}}>
                <div  className="slider-active">
                    <div className="single-slider slider-height d-flex align-items-center" style={{background: "#662D91"}}
                        data-background="/front/assets/img/banner/banner9.jpg">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="hero-text mt-90">
                                        <div className="hero-slider-caption text-white ">
                                            <h1 className="text-white" style={{fontSize: "65px"}}>Sell Your Extra <br />DIABETIC SUPPLIES</h1>
                                            <p className="text-white">Florida Medical Surplus will pay you cash for your extra Diabetic Strips, Diabetic Lancets and Diabetic Supplies.</p>
                                        </div>
                                        <div className="hero-slider-btn">
                                                <Link to="/shop" className="c-btn">Select Brand<i className="far fa-plus"></i> </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="slider-img d-none d-lg-block">
                                        <img src="/front/assets/img/banner/banner9.jpg" alt="" height="400"  width="100%"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div  className="slider-active">
                    <div className="single-slider slider-height d-flex align-items-center"
                        data-background="/front/assets/img/banner/banner2.png">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="hero-text mt-90">
                                        <div className="hero-slider-caption ">
                                            <h2 className="text-white" style={{fontSize: "49px"}}>Save time searching for America's #1 diabetic medical supply buyback site</h2>
                                        </div>
                                        <div className="hero-slider-btn">
                                            <Link to="/shop" className="c-btn">Select Brand<i className="far fa-plus"></i> </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="slider-img d-none d-lg-block">
                                        <img src="/front/assets/img/banner/banner2.png" className="w-75" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div  className="slider-active">
                    <div className="single-slider slider-height d-flex align-items-center"
                        data-background="/front/assets/img/banner/stripe.png">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="hero-text mt-90">
                                        <div className="hero-slider-caption ">
                                            <h2 className="text-white" style={{fontSize: "49px"}}>Save time searching for America's #1 diabetic medical supply buyback site</h2>
                                        </div>
                                        <div className="hero-slider-btn">
                                            <Link to="/shop" className="c-btn">Select Brand<i className="far fa-plus"></i> </Link>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col-xl-12 col-lg-12">
                                    <div className="hero-text d-none d-lg-block w-100">
                                        <img src="/front/assets/img/banner/stripe.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
            </Slider>
        </section>
    )
}

export default HomeSlider
