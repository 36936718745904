import React, {useEffect , useState} from 'react'
import { Link , useHistory  } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Loader from "react-loader-spinner";
import axios from "axios";

const URL = "https://floridamedicalsurplus.com/admin-panel/";

function Success({ match }) {
    
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [orderData, setOrderData] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        fetch(
            URL+'api/order-detail/'+match.params.id,
          {
            method: "GET",
            headers: new Headers({
              Accept: "application/json"
            })
          }
        )
          .then(res => res.json())
          .then(response => {
            setIsLoading(false);
            setOrderData(response.data);
           

          })
          .catch(error => console.log(error));
      }, []);

      console.log('orderData',orderData);
    return (
        <div>
            <Header /> 
            <main>
                <div className="breadcrumb-area pt-45 pb-45" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h2>Order Success</h2>
                                    </div>
                                    <ul className="breadcrumb-menu">
                                        <li><a href="#">Home</a></li>
                                        <li><span>Order Success</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="login-area pt-100 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <h1>Order Created Successfully!. We will contact you Shortly!</h1>
                                <section className="h-100 gradient-custom">
                                    <div className="container h-100">
                                        <div className="row d-flex justify-content-center align-items-center h-100">
                                        <div className="col-lg-12 col-xl-12">
                                            <div className="card" >
                                            <div className="card-header px-4 py-5">
                                                <h5 className="text-muted mb-0">Thanks for your Order, 
                                                <span >
                                                    {
                                                        (orderData) ? orderData.first_name + ' '+ orderData.last_name : ''
                                                    }
                                                </span>!</h5>
                                            </div>
                                            <div className="card-body p-4">
                                                <div className="d-flex justify-content-between align-items-center mb-4">
                                                <p className="lead fw-normal mb-0" >Order Detail</p>
                                                </div>
                                                {
                                                    (orderData) ?
                                                    orderData.order_list?.map((list, index) => (
                                                <div className="card shadow-0 border mb-4" key={index}>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-4 d-flex align-items-center">
                                                                <p className="text-muted mb-0">{list.sell_device.device_name}</p>
                                                            </div>
                                                            <div className="col-md-2 d-flex align-items-center">
                                                                <p className="text-muted mb-0 small">{list.sell_expire.name}</p>
                                                            </div>
                                                            <div className="col-md-2 d-flex align-items-center">
                                                                <p className="text-muted mb-0 small">{list.sell_condition.name}</p>
                                                            </div>
                                                            <div className="col-md-2 d-flex align-items-center">
                                                                <p className="text-muted mb-0 small">Qty: {list.qty}</p>
                                                            </div>
                                                            <div className="col-md-1 d-flex align-items-center">
                                                                <p className="text-muted mb-0 small">${list.price}</p>
                                                            </div>
                                                            <div className="col-md-1 d-flex align-items-center">
                                                                <p className="text-muted mb-0 small">${list.price * list.qty}</p>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div> )) :''
                                                }
                                                {
                                                    (orderData) ? 
                                                        (orderData.bounus > 0) ?
                                                        <div className="d-flex justify-content-between pt-2">
                                                        <p className="fw-bold mb-0">Bounus</p>
                                                        <p className="text-muted mb-0"><span className="fw-bold me-4">Total</span> $orderData.bounus</p>
                                                        </div> :'':''
                                                }
                                                <div className="d-flex justify-content-between pt-2">
                                                <p className="fw-bold mb-0">Order Details</p>
                                                <p className="text-muted mb-0">                                                </p>
                                                </div>

                                                <div className="d-flex justify-content-between">
                                                <p className="text-muted mb-0">User Name : 
                                                {
                                                        (orderData) ? orderData.first_name + ' '+ orderData.last_name : ''
                                                    }
                                                </p>
                                                <p className="text-muted mb-0"></p>
                                                </div>

                                                <div className="d-flex justify-content-between">
                                                <p className="text-muted mb-0">User Email : 
                                                {
                                                        (orderData) ? orderData.email : ''
                                                    }
                                                </p>
                                                <p className="text-muted mb-0"></p>
                                                </div>

                                                <div className="d-flex justify-content-between">
                                                <p className="text-muted mb-0">
                                                {
                                                        (orderData) ? (orderData.shipping_label) ? 
                                                            <a href={orderData.shipping_label} className="c-btn mt-3" target="_blank"> Print Shipping Label</a>
                                                        :'' : ''
                                                    }
                                                </p>
                                                <p className="text-muted mb-0"></p>
                                                </div>
                                            </div>
                                            <div className="card-footer border-0 px-4 py-5" >
                                                <h5 className="d-flex align-items-center justify-content-end text-primary text-uppercase mb-0">Total paid: <span className="h2 mb-0 ms-2">${
                                                        (orderData) ? orderData.total_price : '0'
                                                    }</span></h5>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
             </main>
            <Footer />
        </div>
    )
}

export default Success
