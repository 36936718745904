import React from 'react'
import { BrowserRouter as Router, Route , Link } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Product_Grid from './Product_Grid';
import Product_List from './Product_List';

function Product({ match }) {

    console.log();

    return (
        <div>
            <Header /> 
            <main>
                <div className="breadcrumb-area pt-45 pb-45" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h1>Sell Now</h1>
                                    </div>
                                    <ul className="breadcrumb-menu">
                                        <li><a href="index.html">Home</a></li>
                                        <li><span>Sell Now</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="product-area pt-100 pb-100">
                    <div className="container">
                        <div className="row mb-20">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="pro-tab1-content pt-15 mb-20 text-md-center">
                                    <h2>Select Your Device</h2>
                                </div>
                            </div>
                        </div>
                        <div className="product-tab-content">
                            <div className="tab-content" id="myTabContent">
                                <Product_Grid brand={match.params.param1}/>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Product
