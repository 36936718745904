import React from 'react'
import { BrowserRouter as Router, Route , Link } from 'react-router-dom';
import Header from './layout/Header';
import Footer from './layout/Footer';
import Category_Grid from './Category_Grid';
import Product_List from './Product_List';

function Shop() {
    return (
        <div>
            <Header /> 
            <main>
                <div className="breadcrumb-area pt-45 pb-45" style={{backgroundImage:"url(/front/assets/img/bg/04.jpg)"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="breadcrumb-wrapper f-left">
                                    <div className="breadcrumb-text">
                                        <h1>Sell Now</h1>
                                    </div>
                                    <ul className="breadcrumb-menu">
                                        <li><a href="index.html">Home</a></li>
                                        <li><span>Sell Now</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="product-area pt-100 pb-100">
                    <div className="container">
                        <div className="row mb-20">
                            {/* <div className="col-xl-4 col-lg-3 col-md-3">
                                <div className="product-02-tab mb-20">
                                    <ul className="nav justify-content-start product-nav" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab"
                                                aria-controls="home" aria-selected="true">
                                                <i className="fas fa-th-large"></i>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                                                aria-controls="profile" aria-selected="false">
                                                <i className="fas fa-bars"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}

                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div className="pro-tab1-content pt-15 mb-20 text-md-center">
                                    <h1>Welcome To Our Diabetic Buy Back Page</h1>
                                    <h3>Select Your Device Category</h3>
                                    <div className="elementor-text-editor elementor-clearfix text-dark">
				<p className='text-dark font-weight-bold'>We will <strong><span>NOT buy OR return</span></strong> any boxes that are marked, soiled, stained, opened, expired or items that smell of cigarette smoke or have been stained yellow because of cigarette smoke.</p><p className='text-dark font-weight-bold'>Sell diabetic test strips with Florida
Medical Surplus LLC and turn your extra supplies into cash today! Boxes must expire 10+ months from the current month and be in mint condition to qualify for a full payout.</p><p className='text-dark font-weight-bold'>Please make sure you are triple checking the expiration date and condition of your items before placing the order. We look forward to your business!</p><p className='text-dark font-weight-bold'>View our Damage Guidelines <a href="/damage" className='text-primary'>here</a>.</p>					</div>
                                </div>
                            </div>
                        </div>
                        <div className="product-tab-content">
                            <div className="tab-content" id="myTabContent">
                                {/* <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab"> */}
                                    <Category_Grid />
                                {/* </div> */}
                                {/* <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <Product_List />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Shop
