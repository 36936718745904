import React from 'react'

function Brand() {
    return (
        <div>
        <div className="brand-area pb-10 pt-40">
            <div className="container">
                <div className="row">
                    <div className="col-xl-2 col-lg-2 col-md-3 col-6">
                        <div className="single-brand mb-60">
                            <img src="/front/assets/img/accu-chek.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 col-6">
                        <div className="single-brand mb-60">
                            <img src="/front/assets/img/contour.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 col-6">
                        <div className="single-brand mb-60">
                            <img src="/front/assets/img/freestyle.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 col-6">
                        <div className="single-brand mb-60">
                            <img src="/front/assets/img/medtronic.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 col-6">
                        <div className="single-brand mb-60">
                            <img src="/front/assets/img/onetouch.jpg" alt="" />
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-3 col-6">
                        <div className="single-brand mb-60">
                            <img src="/front/assets/img/true-metrix.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="brand-area pb-40">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h2>Cash for Your Extra Diabetic Supplies!</h2>
                    </div>
                </div>
            </div>
        </div>
        </div>

    )
}

export default Brand
