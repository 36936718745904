import React, { useState } from 'react'
import Header from './layout/Header';
import HomeSlider from './layout/HomeSlider';
import Brand from './layout/Brand';
import Footer from './layout/Footer';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

function Home() {

    const [show, setShow] = useState(true);
    const modalShow = localStorage.getItem("model"); 
    function handleClose() {
        localStorage.setItem('model','yes');
        setShow(false);
    }
    
    return (
        <div>
            <Header /> 
            <main>
                <HomeSlider />
                <Brand />
                <div className="features-02-area pt-100 pb-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 offset-lg-3 offset-xl-3">
                                <div className="section-title text-center mb-65">
                                    <h2 className='text-white'>Simple Steps to Start Selling</h2>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="feature-02-wrapper text-center mb-30">
                                    <div className="p-feature-text">
                                        <h3>Add Your Products</h3>
                                        <div className="feature-02-icon">
                                            <img src="/front/assets/img/shopping-basket.png" alt="" height="75"/>
                                        </div>
                                        <p>Visit our Sell Now page to select the products that you have for sale and add them to your order. It is quick and easy. and you will see the total that you will receive before you proceed.</p>
                                        <a href="#"><i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="feature-02-wrapper text-center mb-30">
                                    <div className="p-feature-text">
                                        <h3>Ship Your Package</h3>
                                        <div className="feature-02-icon">
                                            <img src="/front/assets/img/rocket-launch.png" alt="" height="75"/>
                                        </div>
                                        <p>After you complete your order and let us know how you would like to be paid. Simply ship us your package. We will email you a prepaid label that you can attach to your package for easy shipping at no cost to you.</p>
                                        <a href="#"><i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="feature-02-wrapper text-center mb-30">
                                    <div className="p-feature-text">
                                        <h3>Get Paid!</h3>
                                        <div className="feature-02-icon">
                                            <img src="/front/assets/img/money-transfer.png" alt="" height="75"/>
                                        </div>
                                        <p>Once you ship your package, we should receive it in 2-4 business days. As soon as we get it, we will verify the contents and get you paid the same day. You can choose from a variety of options for payment.</p>
                                        <a href="#"><i className="fal fa-long-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                            <div className="hero-text mt-30"><div className="hero-slider-btn">
                                
                                <Link to="/shop" className="c-btn">Sell Now <i className="far fa-plus"></i></Link>

                                </div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="features-area pt-60 pb-00 grey-2-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="features-wrapper mb-30">
                                    <div className="features-icon fe-3 mb-20">
                                        <i className="fal fa-unlock-alt"></i>
                                    </div>
                                    <div className="features-text">
                                        <h3>Secure & Safe</h3>
                                        <p>With Florida Medical Surplus, you never have to worry about us selling your information or personal details. You are a valued customer, so we keep everything confidential.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="features-wrapper mb-30">
                                    <div className="features-icon fe-1  mb-30">
                                        <i className="fal fa-users"></i>
                                    </div>
                                    <div className="features-text">
                                        <h3>Satisfied Sellers</h3>
                                        <p>Take a moment and visit our reviews tab to see how we treat our customers. We will always communicate with you and make sure your experience with us is seamless.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6">
                                <div className="features-wrapper mb-30">
                                    <div className="features-icon fe-2 mb-30">
                                        <i className="fal fa-usd-circle"></i>
                                    </div>
                                    <div className="features-text">
                                        <h3>Best Prices</h3>
                                        <p>Florida Medical Surplus leads the way in pricing. We want you to get the biggest payout possible, so click sell now and browse our product list to see how much you can make with your unused extra diabetic supplies</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                            <div id='ndrsl-6231e3520a816b72e0df3f38' className='ndrsl-widget '><p className='ndrslpb-6231e3520a816b72e0df3f38-622b64f73aa15170e240ba3a do-not-remove'><a rel='dofollow' target='_blank' href='https://endorsal.io/platform/other-website-reviews'>Other display reviews</a> by Endorsal</p></div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                            <div className="hero-text mt-30"><div className="hero-slider-btn">
                                <Link to="/shop" className="c-btn">Let Get Started <i className="far fa-plus"></i></Link>
                                </div></div>
                            </div>
                        </div>
                    </div>
                    <div className="post-text  mb-20">
                        <blockquote  style={{backgroundColor: "#D90D32", textAlign: "left"}} >
                            <h1 style={{color: "white"}}>Let’s Sell Your Unexpired Diabetic Supplies</h1>
                        </blockquote>
                    </div>
                </div>
                
            </main>
            <Footer />
           
        </div>
       
    )
}

export default Home
